import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Upload, Layout, message, DatePicker, PageHeader } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { createCollection, cleanStore, getCollections } from './../../../redux/actions/collections';
import { uploadImage, removeImage, formItemLayout, validateURL } from './../../../utils/helpers';
import moment from 'moment';
import tooltip from './../../../utils/tooltips.json';
var Web3 = require('web3');

// "Web3.providers.givenProvider" will be set if in an Ethereum supported browser.
var web3 = new Web3();

const { Content } = Layout;

const ColecctionForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem('user'));
  const [form] = Form.useForm();
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { loadingCollection, collection, collections } = useSelector(({ collections }) => collections);

  useEffect(() => {
    loadingCollection && message.loading('Creating collection...', 1);

    if (collection?.status === 200) {
      form.resetFields();
      dispatch(cleanStore());
      setDefaultFileList([]);
      message.success(`${collection.data}`, 2.5);
      history.push('/list/collections');
    }

    /* eslint-disable */
  }, [loadingCollection]);
  /* eslint-enable */

  useEffect(() => {
    dispatch(getCollections());
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  const formSuccess = values => {
    const data = {
      ...values,
      creator: user?.email,
      creationDate: moment().format(),
      startDate: startDate,
      endDate: endDate,
      imgCDNURL: values.imgCDNURL && `${values.imgCDNURL[0].xhr}`,
    };

    dispatch(createCollection(data));
  };

  const formFailed = error => {
    console.log('Error', error);
  };

  const normFile = e => {
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  function onChangeDate(value, dateString) {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  }

  const validateContractAddress = (rule, value, callback) => {
    const exist = value && web3.utils.isAddress(value);
    if (!exist) {
      callback('That is not a valid contract address!');
    } else if (collections.find(collection => collection.contractAddress === value)) {
      callback('That contract address is already assigned to another collection!');
    } else {
      callback();
    }
  };

  return (
    <div>
      {' '}
      <Content
        className="site-layout-background"
        style={{
          overflow: 'auto',
          padding: 20,
          margin: 20,
          minHeight: 280,
          backgroundColor: 'White',
        }}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={formSuccess}
          onFinishFailed={formFailed}
          scrollToFirstError
        >
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title=" "
            style={{ padding: '0' }}
          />
          <Form.Item
            name="name"
            label="Collection name"
            rules={[
              {
                required: true,
                message: 'Please input the collection name!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="New collection name here." />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Some description for the collection here" />
          </Form.Item>

          <Form.Item
            name="contractAddress"
            label="Contract address:"
            tooltip={tooltip?.collection.contractAddress}
            rules={[
              {
                required: true,
                message: 'Please add the contract address!',
              },
              { validator: validateContractAddress },
            ]}
          >
            <Input placeholder="Contract address here." />
          </Form.Item>

          <Form.Item
            name="externalURL"
            label="URL:"
            rules={[
              {
                required: true,
                message: 'Please add the URL!',
                whitespace: true,
              },
              { validator: validateURL },
            ]}
          >
            <Input placeholder="https://example.com" />
          </Form.Item>
          <Form.Item name="imgCDNURL" label="Multimedia:" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload
              accept="image/*"
              customRequest={uploadImage}
              onChange={normFile}
              onRemove={removeImage}
              className="image-upload-grid"
              listType="picture"
            >
              {defaultFileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
            </Upload>
          </Form.Item>

          <Form.Item
            name="date"
            label="Dates"
            rules={[
              {
                required: true,
                message: 'Please select a date!',
              },
            ]}
          >
            <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" onChange={onChangeDate} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Create collection
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </div>
  );
};

export default ColecctionForm;
