import { useEffect, useState } from 'react';
import { Button, Form, message, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Column from 'antd/lib/table/Column';
import Text from 'antd/lib/typography/Text';
import marketplaceContractABI from '../../services/web3/abis/Marketplace.json';
import Web3 from 'web3';
import { useAddress } from '../../context/PassportProvider';
import { MARKETPLACE_CONTRACT_ADDRESS } from '../../config';
import CustomModalTransactionsStep from '../nfts/modalTransactionSteps/CustomModalTransactionSteps.component';
import { LoadingOutlined } from '@ant-design/icons';
const web3 = new Web3(Web3.givenProvider);

const ReleaseFundsContent = () => {
  const [form] = Form.useForm();
  const [payeesDataSource, setPayeesDataSource] = useState();
  const [marketplaceBalance, setMarketplaceBalance] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [stepBlockchainTx, setStepBlockchainTx] = useState(0);
  const [userAcceptedTXHash, setUserAcceptedTXHash] = useState();
  const walletAddress = useAddress();

  const marketplaceContract = new web3.eth.Contract(marketplaceContractABI, MARKETPLACE_CONTRACT_ADDRESS);

  const getMarketPlaceBalance = async () => {
    let _marketplaceBalance = await web3.eth.getBalance(MARKETPLACE_CONTRACT_ADDRESS);
    setMarketplaceBalance(parseFloat(web3.utils.fromWei(_marketplaceBalance, 'ether')));
  };

  const getPayees = async () => {
    const totalPayees = await marketplaceContract.methods?.totalPayees().call();
    const _payeesDataSource = [];
    for (let index = 0; index < totalPayees; index++) {
      const payee = await marketplaceContract.methods?.payee(index).call();
      const shares = await marketplaceContract.methods?.shares(payee).call();
      const payeeBalance = await marketplaceContract.methods?.released(payee).call();
      _payeesDataSource.push({
        id: index + 1,
        payeeAddress: payee,
        shares,
        payeeBalance: web3.utils.fromWei(payeeBalance, 'ether'),
      });
    }
    setPayeesDataSource(_payeesDataSource);
  };

  useEffect(() => {
    getPayees();
    getMarketPlaceBalance();
  }, []);

  const releaseFunds = async () => {
    setModalVisible(true);
    setStepBlockchainTx(0);
    setUserAcceptedTXHash();
    try {
      let _userAcceptedTXHash;
      await marketplaceContract?.methods
        .withdrawPaymentSplitter()
        .send({ from: walletAddress })
        .once('transactionHash', function (hash) {
          setStepBlockchainTx(1);
          setUserAcceptedTXHash(hash);
          _userAcceptedTXHash = hash;
        })
        .once('receipt', function (receipt) {
          setStepBlockchainTx(2);
          setModalVisible(false);
          message.success('Funds successfully released', 3.5);
        })
        .on('error', function (error) {
          setModalVisible(false);
          setStepBlockchainTx();
          setUserAcceptedTXHash();
          error.message.includes('MetaMask') && message.error(error.message, 3.5);
          !error.message.includes('MetaMask') &&
            message.error(
              <a href={`https://goerli.etherscan.io/tx/${_userAcceptedTXHash}`} target="_blank" rel="noreferrer">
                View error on etherscan
              </a>,
              5
            );
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  const submitForm = async () => {
    releaseFunds();
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  function showTotal(total) {
    return `Total ${total} items`;
  }

  return (
    <>
      <Content
        className="site-layout-background"
        style={{
          overflow: 'auto',
          padding: 20,
          margin: 20,
          minHeight: 280,
          backgroundColor: 'White',
        }}
      >
        <Content>
          {payeesDataSource ? (
            <>
              <Text>Payees</Text>
              <Table
                dataSource={payeesDataSource}
                style={{ margin: 15, maxHeight: '500px' }}
                size="middle"
                rowKey="id"
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30'],
                  total: 10,
                  showTotal,
                }}
              >
                <Column title="Address" dataIndex="payeeAddress" key="payeeAddress" ellipsis={true} />
                <Column
                  title="Shares"
                  dataIndex="shares"
                  key="shares"
                  width="10%"
                  ellipsis={true}
                  render={percentage => `${percentage}%`}
                />
                <Column
                  title="Balance"
                  dataIndex="payeeBalance"
                  key="payeeBalance"
                  width="20%"
                  ellipsis={true}
                  render={amount => `${amount} ETH`}
                />
              </Table>
            </>
          ) : (
            <LoadingOutlined />
          )}
        </Content>
        <Form
          layout={'vertical'}
          form={form}
          style={{ width: '80%', marginTop: '2rem' }}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Button type="primary" htmlType="submit" disabled={marketplaceBalance === 0 || stepBlockchainTx === 1}>
            Release funds
          </Button>
          {marketplaceBalance === 0 && (
            <Text disabled={true} style={{ display: 'block', marginTop: '1rem' }}>
              This contract has no balance
            </Text>
          )}
        </Form>
      </Content>
      <CustomModalTransactionsStep
        visible={modalVisible}
        stepBlockchainTx={stepBlockchainTx}
        userAcceptedTXHash={userAcceptedTXHash}
        footer={
          stepBlockchainTx === 1 && [
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Wait and close modal
            </Button>,
          ]
        }
      />
    </>
  );
};

export default ReleaseFundsContent;
