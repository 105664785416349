import { ActionTypes } from '../../utils/constants';

const initialState = {
  collection: [],
  loadingCollection: false,

  collectionGet: [],
  loadingCollectionGet: false,

  collectionEdit: [],
  loadingCollectionEdit: false,

  removedCollection: [],
  loadingRemoveCollection: false,

  collections: [],
  loadingCollections: false,

  filteredCollections: [],
};

const collections = (state = initialState, { type, payload }) => {
  switch (type) {
    /* Add collection to DB */
    case ActionTypes.CREATE_COLLECTION_REQUEST:
      return {
        ...state,
        loadingCollection: true,
      };
    case ActionTypes.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingCollection: false,
        collection: payload,
      };
    case ActionTypes.CREATE_COLLECTION_FAILURE:
      return {
        ...state,
        loadingCollection: false,
      };

    /* Import collection to DB */
    case ActionTypes.IMPORT_COLLECTION_REQUEST:
      return {
        ...state,
        loadingCollection: true,
      };
    case ActionTypes.IMPORT_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingCollection: false,
        collection: payload,
      };
    case ActionTypes.IMPORT_COLLECTION_FAILURE:
      return {
        ...state,
        loadingCollection: false,
      };

    /* Get collection to DB */
    case ActionTypes.GET_COLLECTION_REQUEST:
      return {
        ...state,
        loadingCollectionGet: true,
      };
    case ActionTypes.GET_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingCollectionGet: false,
        collectionGet: payload,
      };
    case ActionTypes.GET_COLLECTION_FAILURE:
      return {
        ...state,
        loadingCollectionGet: false,
      };

    /* Edit collection from DB */
    case ActionTypes.EDIT_COLLECTION_REQUEST:
      return {
        ...state,
        loadingCollectionEdit: true,
      };
    case ActionTypes.EDIT_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingCollectionEdit: false,
        collectionEdit: payload,
      };
    case ActionTypes.EDIT_COLLECTION_FAILURE:
      return {
        ...state,
        loadingCollectionEdit: false,
      };

    /* Remove collection from DB*/
    case ActionTypes.REMOVE_COLLECTION_REQUEST:
      return {
        ...state,
        loadingRemoveCollection: true,
      };
    case ActionTypes.REMOVE_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingRemoveCollection: false,
        removedCollection: payload,
      };
    case ActionTypes.REMOVE_COLLECTION_FAILURE:
      return {
        ...state,
        loadingRemoveCollection: false,
        removedCollection: payload,
      };

    /* Get all Collections */
    case ActionTypes.GET_COLLECTIONS_REQUEST:
      return {
        ...state,
        filteredCollections: true,
      };
    case ActionTypes.GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        filteredCollections: false,
        collections: payload,
      };
    case ActionTypes.GET_COLLECTIONS_FAILURE:
      return {
        ...state,
        filteredCollections: false,
      };

    case ActionTypes.GET_FILTERED_COLLECTIONS:
      return {
        ...state,
        filteredCollections: payload,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        collection: null,
        collectionEdit: null,
        collectionGet: null,
        removedCollection: null,
      };

    default:
      return state;
  }
};

export default collections;
