import { MARKETPLACE_API_URL } from '../config';
import moment from 'moment';

export const leaderboardTypes = {
  OVERALL: {
    id: 'overall',
    label: 'Overall',
    lastColumnTitle: 'Overall score',
    url: '',
  },
  DIC_PUNCHES: {
    id: 'dic_punches',
    label: 'Dic punches',
    lastColumnTitle: 'Dic Punches Avg.',
    url: `${MARKETPLACE_API_URL}/wiki/leaderboard`,
  },
  STORY_FYBS: {
    id: 'story_FYBs',
    label: 'Story FYBs',
    lastColumnTitle: 'Story FYBs',
    url: `${MARKETPLACE_API_URL}/wiki/leaderboard/storyVotes`,
  },
  TRADES: {
    id: 'trades',
    label: 'Trades',
    lastColumnTitle: 'Trade offers',
    url: `${MARKETPLACE_API_URL}/offers/leaderboard`,
  },
};

export const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: 'NFT Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
  },
];

export const getLeaderboardDataSource = ({ id, content }) => {
  switch (id) {
    case leaderboardTypes.OVERALL.id:
      return content;
    case leaderboardTypes.DIC_PUNCHES.id:
      return content?.map((item, index) => {
        return {
          key: `${index + 1}`,
          rank: index + 1,
          name: item.charactersData.name,
          username: item.charactersData.nftDetail?.owner?.username ?? 'Unbridged NFT',
          score: item.avgAmount,
        };
      });
    case leaderboardTypes.STORY_FYBS.id:
      return content?.map((item, index) => {
        return {
          key: `${index + 1}`,
          rank: index + 1,
          name: item.name,
          username: item.nft?.owner?.username,
          score: item.votes,
        };
      });
    case leaderboardTypes.TRADES.id:
      return content?.map((item, index) => {
        return {
          key: `${index + 1}`,
          rank: index + 1,
          name: item.nftDetail.name,
          username: item.nftDetail?.owner?.username,
          score: item.count,
        };
      });
    default:
      return content;
  }
};

export const leaderboardsUrlMaker = (leaderboard, dates, limit) => {
  const selectedLeaderboard = leaderboardTypes[leaderboard.toUpperCase()];
  const isAllTime = dates[0] === null && moment(dates[1]).format('YYYY-DD-MM') === moment().format('YYYY-DD-MM');
  const startDate = moment(dates[0] ?? 0)
    .startOf('day')
    .format();
  const endDate = moment(dates[1] ?? undefined)
    .endOf('day')
    .format();

  const path = limit || 'null';
  const query = isAllTime ? '' : `?startDate=${startDate}&endDate=${endDate}`;

  return `${selectedLeaderboard.url}/${path}${query}`;
};

export const laederboardCSVMaker = (columns, dataSource) => {
  const titles = columns.map(column => column.title).join(',');
  const rows = dataSource.map(item => {
    const result = [];
    Object.entries(item).forEach(([key, value]) => {
      if (key !== 'key') result.push(value);
    });
    return result.join(',');
  });
  return [titles, ...rows].join('\n');
};

export const downloadCSVLeaderboard = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', `${fileName}.csv`);
  a.click();
};
