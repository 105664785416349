import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
	Form,
	Input,
	Button,
	Layout,
	message,
	PageHeader,
	Select,
	InputNumber,
} from 'antd';
import 'antd/dist/antd.css';
import {
	cleanStore,
	createAchievement,
} from '../../../redux/actions/achievements';
import {formItemLayout} from './../../../utils/helpers';
import tooltip from './../../../utils/tooltips.json';
import {
	AchievementPoints,
	AchievementTitles,
	AchievementTypes,
} from '../../../utils/achievements.data';

const {Content} = Layout;

const AchievementForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [form] = Form.useForm();
	const {loadingAchievement, achievement} = useSelector(
		({achievements}) => achievements
	);

	useEffect(() => {
		loadingAchievement && message.loading('Creating achievement...', 1);
		if (achievement?.status === 200) {
			form.resetFields();
			dispatch(cleanStore());
			message.success(`${achievement.data.message}`, 2.5);
			history.push('/list/achievements');
		}

		if (achievement?.status === 500)
			message.warning(`${achievement?.data.message}`);

		// eslint-disable-next-line
	}, [loadingAchievement, achievement]);

	const formSuccess = (data) => {
		dispatch(createAchievement(data));
	};

	const formFailed = (error) => {
		console.log('Error', error);
	};

	return (
		<div>
			{' '}
			<Content
				className='site-layout-background'
				style={{
					overflow: 'auto',
					padding: 20,
					margin: 20,
					minHeight: 280,
					backgroundColor: 'White',
				}}
			>
				<Form
					{...formItemLayout}
					form={form}
					name='register'
					onFinish={formSuccess}
					onFinishFailed={formFailed}
					scrollToFirstError
				>
					<PageHeader
						className='site-page-header'
						onBack={() => window.history.back()}
						title=' '
						style={{padding: '0'}}
					/>
					<Form.Item
						name='type'
						label='Type'
						rules={[
							{
								required: true,
								message: 'Please sellect one!',
								whitespace: true,
							},
						]}
					>
						<Select placeholder={'Select an achievement type'}>
							{Object.values(AchievementTypes).map((achievementType) => (
								<Select.Option value={achievementType}>
									{AchievementTitles[achievementType]}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='requiredAmount'
						label='Quantity'
						tooltip={tooltip?.achievement.quantity}
						dependencies={['type']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please select a quantity',
							},
							({getFieldValue}) => ({
								validator(_, value) {
									if (
										value &&
										getFieldValue('type') === AchievementTypes.NEW_PASSPORT &&
										value !== 1
									) {
										return Promise.reject(
											new Error(
												`You must select "1" for ${
													AchievementTitles[AchievementTypes.NEW_PASSPORT]
												}`
											)
										);
									}
									return Promise.resolve();
								},
							}),
						]}
					>
						<InputNumber min={1} />
					</Form.Item>
					<Form.Item
						name='points'
						label='Achievement points'
						rules={[
							{
								required: true,
								message: 'Please select an amount',
								whitespace: true,
							},
						]}
						tooltip={tooltip?.achievement.points}
					>
						<Select placeholder={'Select earned points'}>
							{AchievementPoints.map((points) => (
								<Select.Option value={points}>{points}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='label'
						label='Label'
						rules={[
							{
								required: true,
								message: 'Please input a label to display the achievement!',
								whitespace: true,
							},
						]}
					>
						<Input placeholder='New achievement label here' />
					</Form.Item>
					<Form.Item
						name='shortDescription'
						label='Short description'
						rules={[
							{
								required: true,
								message: 'Please input a short description!',
								whitespace: true,
							},
						]}
					>
						<Input placeholder='A short description is needed (e.g.: Traded NFTs 5 times)' />
					</Form.Item>
					<Form.Item
						name='longDescription'
						label='Long description'
						rules={[
							{
								required: true,
								whitespace: true,
							},
						]}
					>
						<Input.TextArea placeholder='Some description for the achievement here' />
					</Form.Item>
					<Form.Item wrapperCol={{offset: 7, span: 16}}>
						<Button type='primary' htmlType='submit'>
							Create achievement
						</Button>
					</Form.Item>
				</Form>
			</Content>
		</div>
	);
};

export default AchievementForm;
