import { ActionTypes } from '../../utils/constants';

const initialState = {
  notifications: null,
  notification: null,
  error: null,
  loadingnotifications: false,
  loadingnotification: false,
  notificationAddedOrUpdated: false,
  notificationDeleted: false,
  notificationSent: false,
};

const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        notifications: payload,
      };

    case ActionTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
        notifications: null,
        error: payload,
      };

    case ActionTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingpNotifications: true,
      };

    case ActionTypes.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingNotification: true,
      };

    case ActionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingNotification: false,
        notification: payload,
      };

    case ActionTypes.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotification: false,
        notification: null,
        error: payload,
      };

    case ActionTypes.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingNotification: true,
        notificationAddedOrUpdated: false,
      };

    case ActionTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingnotification: false,
        notification: payload,
        notificationAddedOrUpdated: true,
      };

    case ActionTypes.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotification: false,
        payload: null,
        error: payload,
        notificationAddedOrUpdated: false,
      };

    case ActionTypes.ADD_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingNotification: true,
        notificationAddedOrUpdated: false,
      };

    case ActionTypes.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingNotification: false,
        notification: payload,
        notificationAddedOrUpdated: true,
      };

    case ActionTypes.ADD_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotification: false,
        error: payload,
        notificationAddedOrUpdated: false,
      };

    case ActionTypes.REMOVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingNotifications: true,
        removedNotification: null,
        notificationDeleted: false,
      };

    case ActionTypes.REMOVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        removedNotification: payload,
        notificationDeleted: true,
      };

    case ActionTypes.REMOVE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
        removedNotification: null,
        error: payload,
        notificationDeleted: false,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        notifications: null,
        notification: null,
        removedNotification: null,
        error: null,
        loadingNotifications: false,
        notificationAddedOrUpdated: false,
        notificationDeleted: false,
      };

    case ActionTypes.SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingNotifications: false,
        notificationSent: false,
      };

    case ActionTypes.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingnotification: false,
        notification: payload,
        notificationSent: true,
      };

    case ActionTypes.SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotification: false,
        payload: null,
        error: payload,
        notificationSent: false,
      };

    default:
      return state;
  }
};

export default notifications;
