import { ActionTypes } from '../../utils/constants';

const initialState = {
  achievement: [],
  loadingAchievement: false,

  achievementGet: null,
  loadingAchievementGet: false,

  achievementEdit: [],
  loadingAchievementEdit: false,

  removedAchievement: [],
  loadingRemovedAchievement: false,

  achievements: [],
  loadingAchievements: false,

  filteredAchievements: [],
};

const achievements = (state = initialState, { type, payload }) => {
  switch (type) {
    /* Add achievement to DB */
    case ActionTypes.CREATE_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingAchievement: true,
      };
    case ActionTypes.CREATE_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        loadingAchievement: false,
        achievement: payload,
      };
    case ActionTypes.CREATE_ACHIEVEMENT_FAILURE:
      return {
        ...state,
        loadingAchievement: false,
        achievement: payload,
      };

    /* Get achievement to DB */
    case ActionTypes.GET_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingAchievementGet: true,
      };
    case ActionTypes.GET_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        loadingAchievementGet: false,
        achievementGet: payload,
      };
    case ActionTypes.GET_ACHIEVEMENT_FAILURE:
      return {
        ...state,
        loadingAchievementGet: false,
      };

    /* Edit achievement from DB */
    case ActionTypes.EDIT_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingAchievementEdit: true,
      };
    case ActionTypes.EDIT_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        loadingAchievementEdit: false,
        achievementEdit: payload,
      };
    case ActionTypes.EDIT_ACHIEVEMENT_FAILURE:
      return {
        ...state,
        loadingAchievementEdit: false,
        achievementEdit: payload,
      };

    /* Remove achievement from DB*/
    case ActionTypes.REMOVE_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingRemovedAchievement: true,
      };
    case ActionTypes.REMOVE_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        loadingRemovedAchievement: false,
        removedAchievement: payload,
      };
    case ActionTypes.REMOVE_ACHIEVEMENT_FAILURE:
      return {
        ...state,
        loadingRemovedAchievement: false,
        removedAchievement: payload,
      };

    /* Get all Collections */
    case ActionTypes.GET_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        filteredAchievements: true,
      };
    case ActionTypes.GET_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        filteredAchievements: false,
        achievements: payload,
      };
    case ActionTypes.GET_ACHIEVEMENTS_FAILURE:
      return {
        ...state,
        filteredAchievements: false,
      };

    case ActionTypes.GET_FILTERED_ACHIEVEMENTS:
      return {
        ...state,
        filteredAchievements: payload,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        achievement: null,
        achievementEdit: null,
        achievementGet: null,
        removedAchievement: null,
      };

    default:
      return state;
  }
};

export default achievements;
