import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, message, Popconfirm, List, Drawer, Card, Tag } from 'antd';
import Joyride from 'react-joyride';
import { useBackoffice } from './../../../context/BackofficeContext';
import { SwapRightOutlined } from '@ant-design/icons';
import { getCollections, removeCollection, cleanStore } from './../../../redux/actions/collections';
import 'antd/dist/antd.css';
import moment from 'moment';
const { Column } = Table;

const SearchTable = ({ data }) => {
  const { tooltips, setTooltips } = useBackoffice();
  const dispatch = useDispatch();
  const [state] = useState({
    run: tooltips,
    continuous: true,
    steps: [
      {
        target: '.table-collections',
        content: 'The first step before creating an NFT is to enter the collections section.',
        disableBeacon: true,
        /* disableOverlayClose: true, */
        hideCloseButton: true,
      },
      {
        target: '.btn-create-collection',
        content: 'To create an NFT we must first create the collection to which it will belong.',
        disableBeacon: true,
        /* disableOverlayClose: true, */
        hideCloseButton: true,
      },
      {
        target: '.list-of-collections',
        content: 'Once created, you will be able to see it here along with its main data.',
        disableBeacon: true,
        /* disableOverlayClose: true, */
        hideCloseButton: true,
      },
      {
        content: (
          <div>
            Now create your first nft.
            <br />
            Click the button!
          </div>
        ),
        disableBeacon: true,
        /* disableOverlayClose: true, */
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: '.btn-premint-nft',
        title: 'Collection created!',
      },
    ],
  });
  const [visible, setVisible] = useState(false);
  const [visibleCollection, setVisibleCollection] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const { collections, filteredCollections, loadingRemoveCollection, removedCollection } = useSelector(
    ({ collections }) => collections
  );

  useEffect(() => {
    dispatch(getCollections());
    dispatch(cleanStore());
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    loadingRemoveCollection && message.loading('Removing collection...', 0.5);

    removedCollection?.status === 200 &&
      dispatch(getCollections()).then(() => {
        message.success(`${removedCollection?.data}`, 2);
        dispatch(cleanStore());
      });

    removedCollection?.status === 500 && message.warning(`${removedCollection?.data}`);
    /* eslint-disable */
  }, [loadingRemoveCollection, removedCollection]);
  /* eslint-enable */

  const handleRemove = record => {
    dispatch(removeCollection(record));
  };

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <h2 style={{ margin: '0' }}>List of collections</h2>
        <Link to="/import/collection" style={{ margin: '0 20px 0 auto' }}>
          <Button className="btn-create-collection" type="secondary" style={{ display: 'flex', alignItems: 'center' }}>
            Import collection
          </Button>
        </Link>

        <Link to="/add/collection" style={{ marginLeft: 'auto' }}>
          <Button className="btn-create-collection" type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Create a new collection
          </Button>
        </Link>
      </div>
    );
  }

  const showDrawer = record => {
    setDrawerData(record);
    setVisible(true);
  };

  const showCollection = record => {
    setDrawerData(record);
    setVisibleCollection(true);
  };
  const onClose = () => {
    setDrawerData([]);
    setVisible(false);
    setVisibleCollection(false);
  };

  const handleTooltip = data => {
    if (data.action === 'close' || data.action === 'reset') {
      setTooltips(false);
    }
  };

  return (
    <>
      {tooltips && (
        <Joyride
          callback={data => handleTooltip(data)}
          steps={state.steps}
          run={state.run}
          continuous={state.continuous}
        />
      )}

      <Table
        className="list-of-collections"
        dataSource={filteredCollections.length > 0 ? filteredCollections : collections}
        style={{ margin: 15 }}
        size="middle"
        title={() => header()}
        rowKey="_id"
      >
        <Column
          title="Collection Name"
          dataIndex="name"
          key="name"
          width="12%"
          ellipsis={true}
          render={(text, record) => (
            <Button type="link" onClick={() => showCollection(record)} style={{ padding: '0' }}>
              {text}
            </Button>
          )}
        />

        <Column
          title="NFTs"
          dataIndex="nfts"
          key="nfts"
          width="5%"
          render={(text, record) => (
            <Button type="link" onClick={() => showDrawer(record)} style={{ padding: '0' }}>
              {text.length}
            </Button>
          )}
        />
        <Column
          title="Contract Address"
          dataIndex="contractAddress"
          key="contractAddress"
          width="10%"
          ellipsis={true}
          render={(text, record) => (
            <span style={{ padding: '0' }}>{` ${text?.slice(0, 6)}...${text?.slice(-5)}`}</span>
          )}
        />

        <Column title="Description" dataIndex="description" key="description" ellipsis={true} />

        <Column title="Creator" dataIndex="creator" key="creator" ellipsis={true} />

        <Column
          title="Status"
          key="status"
          ellipsis={true}
          render={(date, record) => (
            <>
              {new Date(record.endDate) < new Date() ? (
                <Tag color="red">Closed {/* {moment(record.endDate).diff(moment(new Date()), 'days')} Ago */}</Tag>
              ) : new Date(record.startDate) < new Date() && new Date() < new Date(record.endDate) ? (
                <Tag color="green">In progress</Tag>
              ) : new Date() < new Date(record.startDate) ? (
                <Tag color="blue">Coming soon</Tag>
              ) : null}{' '}
            </>
          )}
        />

        <Column
          title="Date of Creation"
          dataIndex="creationDate"
          key="date"
          defaultSortOrder="descend"
          width="15%"
          sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
          render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
        />

        <Column
          title="Action"
          key="actions"
          width="15%"
          dataIndex="actions"
          render={(actions, record) => (
            <span>
              <Link to={`/edit/collection/${record._id}`} style={{ margin: '0 10px 0 0', padding: '0' }} type="link">
                Edit
              </Link>
              <Popconfirm
                title="Are you sure to delete this collection?"
                onConfirm={() => handleRemove(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  Remove
                </Button>
              </Popconfirm>
            </span>
          )}
        />
      </Table>
      <Drawer title={drawerData?.name} placement="right" onClose={onClose} visible={visible} width={'22%'}>
        <List
          size="small"
          bordered
          dataSource={drawerData?.nfts?.filter(nft => nft.edition.number === 1)}
          renderItem={(item, index) => (
            <List.Item>
              {item?.name} - {item?.edition?.total} editions -{' '}
              {item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}
            </List.Item>
          )}
        />
      </Drawer>
      <Drawer
        title={` ${drawerData?.name} `}
        placement="right"
        onClose={onClose}
        visible={visibleCollection}
        width={500}
      >
        {drawerData && (
          <Card
            style={{ display: 'flex', flexDirection: 'column', width: 450 }}
            cover={
              <img
                src={`${drawerData.imgCDNURL}`}
                alt="NFT img"
                style={{ maxWidth: 450, maxHeight: 250, objectFit: 'contain' }}
              />
            }
          >
            <Card.Meta title={`${drawerData?.name}`} description={drawerData.description} />
            <p></p>
            <p>
              {moment(drawerData.startDate).format('MMM Do YY, h:mm a')} <SwapRightOutlined />
              {'  '}
              {moment(drawerData.endDate).format('MMM Do YY, h:mm a')}
            </p>

            <a
              href={drawerData.externalURL}
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex', margin: '200 0 0 0 ' }}
            >
              {drawerData.externalURL}
            </a>
          </Card>
        )}
      </Drawer>
    </>
  );
};

export default SearchTable;
