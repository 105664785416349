import React, { useEffect } from 'react';
import { usePassportContext } from '../../context/PassportProvider';

const RedirectHandler = () => {
  const { loginCallbackEvm } = usePassportContext();

  useEffect(() => {
    loginCallbackEvm();

    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        inset: '0 0 auto auto',
        width: '100vw',
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
        backgroundColor: '#ffffff',
        zIndex: '9999',
      }}
    />
  );
};

export default RedirectHandler;
