import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Form, Input, Button, Layout, PageHeader, Spin, Space, notification, DatePicker, Upload } from 'antd';
import { PlusOutlined, MinusOutlined, UploadOutlined } from '@ant-design/icons';
import { getPoll, cleanStore, editPoll, addPoll } from '../../../redux/actions/polls';
import { uploadImage, removeImage, formItemLayout } from '../../../utils/helpers';

const { Content } = Layout;

const PollsForm = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { poll: pollData, pollAddedOrUpdated, error, loadingPoll } = useSelector(({ polls }) => polls);
  const [poll, setPoll] = useState();
  /* eslint-disable-next-line */
  const [rand, setRand] = useState();
  const [id] = useState(pathname.split('/edit/poll/')[1]);

  useEffect(() => {
    // Make a copy of the poll data to use it on this component
    // and avoid mutating the state
    if (pollData) setPoll(JSON.parse(JSON.stringify(pollData)));
  }, [pollData]);

  useEffect(() => {
    if (pollAddedOrUpdated) {
      history.push('/list/polls');
    }
  }, [pollAddedOrUpdated, history]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notification.error({
        message: typeof error === 'string' ? error.replace(/<\/?[^>]+(>|$)/g, '') : JSON.stringify(error),
      });
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(getPoll(id));
    } else {
      setPoll({
        questionnaire: [
          {
            question: null,
            options: [
              {
                answer: null,
                votes: 0,
              },
            ],
          },
        ],
      });
    }

    return () => {
      dispatch(cleanStore());
    };
  }, [dispatch, pathname, id]);

  async function formSuccess(v) {
    const values = await form.validateFields();
    if (values.endDate) {
      values.endDate = new Date(values.endDate).getTime();
    }
    const questionnaire = values.questionnaire.map((question, index) => {
      const options = question.options.map((option, indexOption) => {
        const image = option?.image?.file?.xhr
          ? option?.image?.file?.xhr
          : id && option?.image
          ? option?.image
          : poll.questionnaire[index]?.options[indexOption]?.image;
        return { ...option, image };
      });
      return { ...question, options };
    });
    values.questionnaire = questionnaire;

    if (id) {
      dispatch(editPoll(poll._id, { ...poll, ...values }));
    } else {
      dispatch(addPoll(values));
    }
  }

  const normFile = e => {
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        overflow: 'auto',
        padding: 20,
        margin: 20,
        minHeight: 280,
        backgroundColor: 'White',
      }}
    >
      {!loadingPoll && poll ? (
        <Form {...formItemLayout} form={form} name="register" onFinish={formSuccess}>
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={id ? `Poll #${poll._id}` : ''}
            style={{ padding: '0 0 16px 0' }}
          />

          <Form.Item
            label="End date"
            name="endDate"
            initialValue={poll.endDate && moment(poll.endDate)}
            rules={[{ required: true, message: 'End date is required' }]}
          >
            <DatePicker defaultValue={moment(poll.endDate)} showTime={true} />
          </Form.Item>

          <Form.Item
            label="Title"
            name="title"
            initialValue={poll.title}
            rules={[{ required: true, message: 'Title is required' }]}
          >
            <Input defaultValue={poll.title} />
          </Form.Item>

          <Form.List name="questionnaire" initialValue={poll.questionnaire}>
            {(fields, { add, remove }) =>
              fields.map(({ key, ...restField }) => (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    label={`Question ${key + 1}`}
                    name={[key, 'question']}
                    initialValue={[key, 'question']}
                    rules={[{ required: true, message: 'Question is required' }]}
                  >
                    <Space>
                      <Input defaultValue={poll.questionnaire[key]?.question} />
                      <Button onClick={() => add()}>
                        <PlusOutlined />
                      </Button>
                      {fields.length > 1 && (
                        <Button onClick={() => remove(key)}>
                          <MinusOutlined />
                        </Button>
                      )}
                    </Space>
                  </Form.Item>

                  <Form.Item colon={false} label=" ">
                    <Form.List name={[key, 'options']} key={key} initialValue={[{}]}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key2, name, ...restField }) => (
                            <Space
                              key={key2}
                              style={{
                                display: 'flex',
                                marginBottom: 8,
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, 'answer']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing answer',
                                  },
                                ]}
                              >
                                <Input
                                  style={{ width: 200, marginBottom: 10 }}
                                  placeholder="Option"
                                  defaultValue={poll.questionnaire[key]?.options[name]?.answer}
                                />
                              </Form.Item>
                              <Form.Item {...restField} name={[name, 'image']}>
                                <Upload
                                  accept="image/*"
                                  customRequest={uploadImage}
                                  getValueFromEvent={normFile}
                                  onRemove={removeImage}
                                  className="image-upload-grid"
                                  defaultFileList={
                                    poll.questionnaire[key]?.options[name]?.image
                                      ? [
                                          {
                                            name: poll.questionnaire[key]?.options[name]?.image,
                                            url: `https://ipfs.io/ipfs/${poll.questionnaire[key]?.options[name]?.image}`,
                                          },
                                        ]
                                      : []
                                  }
                                >
                                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                              </Form.Item>

                              {fields.length > 1 && (
                                <Button onClick={() => remove(name)}>
                                  <MinusOutlined />
                                </Button>
                              )}
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </div>
              ))
            }
          </Form.List>

          <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {id ? 'Edit poll' : 'Add poll'}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      )}
    </Content>
  );
};

export default PollsForm;
