export const NFTTableDrawerState = {
  NFT: 'NFT',
  EDITIONS: 'EDITIONS',
};

export const NFTModalState = {
  LIST: 'LIST',
  LIST_AGAIN: 'LIST_AGAIN',
  MINT: 'MINT',
};
