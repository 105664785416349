import React, { useState } from 'react';
import moment from 'moment';
import LeaderboardsBar from '../leaderboardsBar/LeaderboardsBar';
import PreviewTable from '../previewTable/PreviewTable';
import { leaderboardTypes } from '../../../utils/leaderboards';

const LeaderboardsContent = () => {
  const [dates, setDates] = useState([null, moment()]);
  const [leaderboard, setLeaderboard] = useState(leaderboardTypes.DIC_PUNCHES.id);
  const [data, setData] = useState({ type: leaderboardTypes.DIC_PUNCHES, content: [] });
  const [loading, setLoading] = useState(false);

  return (
    <>
      <LeaderboardsBar
        dates={dates}
        setDates={setDates}
        leaderboard={leaderboard}
        setLeaderboard={setLeaderboard}
        setData={setData}
        setLoading={setLoading}
      />
      <PreviewTable data={data} loading={loading} />
    </>
  );
};

export default LeaderboardsContent;
