import { ActionTypes } from "../../utils/constants";

const initialState = {
  polls: null,
  poll: null,
  error: null,
  loadingPolls: false,
  loadingPoll: false,
  pollAddedOrUpdated: false,
  pollDeleted: false,
};

const polls = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_POLLS_SUCCESS:
      return {
        ...state,
        loadingPolls: false,
        polls: payload,
      };
    
    case ActionTypes.GET_POLLS_FAILURE:
      return {
        ...state,
        loadingPolls: false,
        polls: null,
        error: payload,
      };

    case ActionTypes.GET_POLLS_REQUEST:
      return {
        ...state,
        loadingPolls: true,
      };

    case ActionTypes.GET_POLL_REQUEST:
      return {
        ...state,
        loadingPoll: true,
      };

    case ActionTypes.GET_POLL_SUCCESS:
      return {
        ...state,
        loadingPoll: false,
        poll: payload,
      };
    
    case ActionTypes.GET_POLL_FAILURE:
      return {
        ...state,
        loadingPoll: false,
        poll: null,
        error: payload,
      };
    
    case ActionTypes.UPDATE_POLL_REQUEST:
      return {
        ...state,
        loadingPoll: true,
        pollAddedOrUpdated: false,
      };

    case ActionTypes.UPDATE_POLL_SUCCESS:
      return {
        ...state,
        loadingPoll: false,
        poll: payload,
        pollAddedOrUpdated: true,
      };
    
    case ActionTypes.UPDATE_POLL_FAILURE:
      return {
        ...state,
        loadingPoll: false,
        payload: null,
        error: payload,
        pollAddedOrUpdated: false,
      };
    
    case ActionTypes.ADD_POLL_REQUEST:
      return {
        ...state,
        loadingPoll: true,
        pollAddedOrUpdated: false,
      };

    case ActionTypes.ADD_POLL_SUCCESS:
      return {
        ...state,
        loadingPoll: false,
        poll: payload,
        pollAddedOrUpdated: true,
      };
    
    case ActionTypes.ADD_POLL_FAILURE:
      return {
        ...state,
        loadingPoll: false,
        error: payload,
        pollAddedOrUpdated: false,
      };
    
    case ActionTypes.REMOVE_POLL_REQUEST:
      return {
        ...state,
        loadingPolls: true,
        removedPoll: null,
        pollDeleted: false,
      };

    case ActionTypes.REMOVE_POLL_SUCCESS:
      return {
        ...state,
        loadingPolls: false,
        removedPoll: payload,
        pollDeleted: true,
      };
    
    case ActionTypes.REMOVE_POLL_FAILURE:
      return {
        ...state,
        loadingPolls: false,
        removedPoll: null,
        error: payload,
        pollDeleted: false,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        polls: null,
        poll: null,
        removedPoll: null,
        error: null,
        loadingPolls: false,
        pollAddedOrUpdated: false,
        pollDeleted: false,
      };

    default:
      return state;
  }
};

export default polls;
