import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Layout,
  PageHeader,
  Spin,
  Space,
  notification as AntdNotification,
  Upload,
  Image,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getNotification, cleanStore, editNotification, addNotification } from '../../../redux/actions/notifications';
import { uploadImage, removeImage, formItemLayout } from './../../../utils/helpers';

const { Content } = Layout;

const NotificationForm = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    notification: notificationData,
    notificationAddedOrUpdated,
    error,
    loadingnotification,
  } = useSelector(({ notifications }) => notifications);
  const [notification, setNotification] = useState();
  const [defaultFileList, setDefaultFileList] = useState([]);
  /* eslint-disable-next-line */
  const [id] = useState(pathname.split('/edit/notification/')[1]);

  useEffect(() => {
    // Make a copy of the notification data to use it on this component
    // and avoid mutating the state
    if (notificationData) setNotification(JSON.parse(JSON.stringify(notificationData)));
  }, [notificationData]);

  useEffect(() => {
    if (notificationAddedOrUpdated) {
      history.push('/list/notifications');
    }
  }, [notificationAddedOrUpdated, history]);

  useEffect(() => {
    if (error) {
      AntdNotification.error({
        message: typeof error === 'string' ? error.replace(/<\/?[^>]+(>|$)/g, '') : JSON.stringify(error),
      });
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(getNotification(id));
    } else {
      setNotification({
        title: null,
        description: null,
        image: null,
      });
    }

    return () => {
      dispatch(cleanStore());
    };
  }, [dispatch, pathname, id]);

  async function formSuccess(v) {
    const values = await form.validateFields();
    const data = {
      ...values,
      image: values.imageEdit
        ? `https://ipfs.io/ipfs/${values.imageEdit[0].xhr}`
        : !values.image
        ? notification.image
        : values.image,
    };

    if (id) {
      dispatch(editNotification(id, { ...notification, ...data }));
    } else {
      dispatch(addNotification(data));
    }
  }

  const formFailed = error => {
    console.log('Error', error);
  };

  const normFile = e => {
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        overflow: 'auto',
        padding: 20,
        margin: 20,
        minHeight: 280,
        backgroundColor: 'White',
      }}
    >
      {!loadingnotification && notification ? (
        <Form {...formItemLayout} form={form} name="register" onFinish={formSuccess} onFinishFailed={formFailed}>
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={id ? `Notification #${id}` : ''}
            style={{ padding: '0 0 16px 0' }}
          />
          <Form.Item
            name="title"
            label="Title"
            initialValue={notification.title}
            rules={[
              {
                required: true,
                message: 'Please input the title!',
              },
            ]}
          >
            <Input placeholder="Title here." />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            initialValue={notification.description}
            rules={[
              {
                required: true,
                message: 'Please input the description!',
              },
            ]}
          >
            <Input.TextArea placeholder="Some description for the notification here" />
          </Form.Item>
          {id && defaultFileList.length === 0 && (
            <Form.Item name="image" label="Image:">
              <Image width={200} src={`${notification?.image}`} />
            </Form.Item>
          )}
          <Form.Item name="imageEdit" label="Image:" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload
              accept="image/*"
              customRequest={uploadImage}
              getValueFromEvent={normFile}
              onRemove={removeImage}
              className="image-upload-grid"
            >
              {defaultFileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {id ? 'Edit Notification' : 'Add Notification'}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      )}
    </Content>
  );
};

export default NotificationForm;
