import React from 'react';
import PassportProvider from './context/PassportProvider';
import BackofficeProvider from './context/BackofficeContext';
import Routes from './../src/components/login/routes/index';
import './App.css';

function App() {
  return (
    <BackofficeProvider>
      <PassportProvider>
        <Routes />
      </PassportProvider>
    </BackofficeProvider>
  );
}

export default App;
