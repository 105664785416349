import axios from 'axios';
import { ActionTypes } from '../../utils/constants';
import { MARKETPLACE_API_URL } from '../../config';

const baseUrl = `${MARKETPLACE_API_URL}/community-notification`;

export const getNotifications = (page, pageSize) => {
  return async dispatch => {
    dispatch(getNotificationsRequest());
    try {
      const response = await axios.get(`${baseUrl}/all/${page}/${pageSize}`, { withCredentials: true });
      return dispatch(getNotificationsSuccess(response.data));
    } catch (err) {
      return dispatch(getNotificationsFailure(err?.response?.data));
    }
  };
};

export const getNotification = id => {
  return async dispatch => {
    dispatch(getNotificationRequest());
    try {
      const response = await axios.get(`${baseUrl}/${id}`, { withCredentials: true });
      return dispatch(getNotificationSuccess(response.data));
    } catch (err) {
      return dispatch(getNotificationFailure(err?.response?.data));
    }
  };
};

export const editNotification = (id, NotificationData) => {
  return async dispatch => {
    dispatch(editNotificationRequest());
    try {
      const response = await axios.put(`${baseUrl}/${id}`, NotificationData, { withCredentials: true });
      return dispatch(editNotificationSuccess(response.data));
    } catch (err) {
      return dispatch(editNotificationFailure(err?.response?.data));
    }
  };
};

export const addNotification = NotificationData => {
  return async dispatch => {
    dispatch(addNotificationRequest());
    try {
      const response = await axios.post(baseUrl, NotificationData, { withCredentials: true });
      return dispatch(addNotificationSuccess(response.data));
    } catch (err) {
      return dispatch(addNotificationFailure(err?.response?.data));
    }
  };
};

export const removeNotification = id => {
  return async dispatch => {
    dispatch(removeNotificationRequest());
    try {
      const response = await axios.delete(`${baseUrl}/${id}`, { withCredentials: true });
      return dispatch(removeNotificationSuccess(response.data));
    } catch (err) {
      return dispatch(removeNotificationFailure(err?.response?.data));
    }
  };
};

export const sendNotification = id => {
  return async dispatch => {
    dispatch(sendNotificationRequest());
    try {
      const response = await axios.put(`${baseUrl}/sendNotification/${id}`, { withCredentials: true });
      return dispatch(sendNotificationSuccess(response.data));
    } catch (err) {
      return dispatch(sendNotificationFailure(err?.response?.data));
    }
  };
};

const getNotificationsRequest = () => {
  return {
    type: ActionTypes.GET_NOTIFICATIONS_REQUEST,
  };
};

const getNotificationsSuccess = payload => {
  return {
    type: ActionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload,
  };
};

const getNotificationsFailure = payload => {
  return {
    type: ActionTypes.GET_NOTIFICATIONS_FAILURE,
    payload,
  };
};

const getNotificationRequest = () => {
  return {
    type: ActionTypes.GET_NOTIFICATION_REQUEST,
  };
};

const getNotificationSuccess = payload => {
  return {
    type: ActionTypes.GET_NOTIFICATION_SUCCESS,
    payload,
  };
};

const getNotificationFailure = payload => {
  return {
    type: ActionTypes.GET_NOTIFICATION_FAILURE,
    payload,
  };
};

const editNotificationRequest = () => {
  return {
    type: ActionTypes.UPDATE_NOTIFICATION_REQUEST,
  };
};

const editNotificationSuccess = payload => {
  return {
    type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS,
    payload,
  };
};

const editNotificationFailure = payload => {
  return {
    type: ActionTypes.UPDATE_NOTIFICATION_FAILURE,
    payload,
  };
};

const addNotificationRequest = () => {
  return {
    type: ActionTypes.ADD_NOTIFICATION_REQUEST,
  };
};

const addNotificationSuccess = payload => {
  return {
    type: ActionTypes.ADD_NOTIFICATION_SUCCESS,
    payload,
  };
};

const addNotificationFailure = payload => {
  return {
    type: ActionTypes.ADD_NOTIFICATION_FAILURE,
    payload,
  };
};

const removeNotificationRequest = () => {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION_REQUEST,
  };
};

const removeNotificationSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION_SUCCESS,
    payload,
  };
};

const removeNotificationFailure = payload => {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION_FAILURE,
    payload,
  };
};

const sendNotificationRequest = () => {
  return {
    type: ActionTypes.SEND_NOTIFICATION_REQUEST,
  };
};

const sendNotificationSuccess = payload => {
  return {
    type: ActionTypes.SEND_NOTIFICATION_SUCCESS,
    payload,
  };
};

const sendNotificationFailure = payload => {
  return {
    type: ActionTypes.SEND_NOTIFICATION_FAILURE,
    payload,
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};
