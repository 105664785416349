import { useState, createContext, useContext } from 'react';
const NFTContext = createContext();

const NFTProvider = ({ children }) => {
  const [rightPanel, setRightPanel] = useState();
  const [modal, showModal] = useState();
  const [stepsModalVisible, setStepsModalVisible] = useState();
  const [stepBlockchainTx, setStepBlockchainTx] = useState();
  const [blockchainTxHash, setBlockchainTxHash] = useState();
  const [approveTransHash, setApproveTransHash] = useState();
  const [listingAgainData, setListingAgainData] = useState();
  const [listingType, setListingType] = useState('');
  const [button, setButton] = useState(true);

  return (
    <NFTContext.Provider
      value={{
        setRightPanel,
        showModal,
        setStepsModalVisible,
        setStepBlockchainTx,
        setBlockchainTxHash,
        setApproveTransHash,
        setListingAgainData,
        setButton,
        setListingType,
        listingType,
        rightPanel,
        modal,
        stepsModalVisible,
        stepBlockchainTx,
        blockchainTxHash,
        approveTransHash,
        listingAgainData,
        button,
      }}
    >
      {children}
    </NFTContext.Provider>
  );
};

export const useNFT = () => {
  return useContext(NFTContext);
};

export default NFTProvider;
