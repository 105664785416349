const NFTStatus = {
  PREMINTED: 'preminted',
  MINTING: 'minting',
  MINTED: 'minted',
  APPROVING: 'approving',
  APPROVED: 'approved',
  LISTING: 'listing',
  LISTED: 'listed',
  FINALIZED: 'finalized',
  SOLD: 'sold',
};

const NFTMarket = {
  PRIMARY_SALE: 'primary_sale',
  SECONDARY_SALE: 'secondary_sale',
};

const ListingType = {
  FIXED_PRICE: 'fixed_price',
  ENGLISH_AUCTION: 'english_auction',
};

module.exports = { NFTStatus, NFTMarket: NFTMarket, ListingType };
