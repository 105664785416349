import { Table, Button, Popconfirm, Progress, Spin, notification, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPosts, pinPodcast, removePost, removeComment, cleanStore } from '../../../redux/actions/posts';
import moment from 'moment';

const TablePosts = () => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState();
  const { posts: postsData, postDeleted, postAddedOrUpdated, error, loadingPosts } = useSelector(({ posts }) => posts);

  useEffect(() => {
    setPosts(JSON.parse(JSON.stringify(postsData)));
  }, [postsData]);

  useEffect(() => {
    if (postDeleted || postAddedOrUpdated) {
      dispatch(getPosts());
    }
  }, [postDeleted, postAddedOrUpdated, dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notification.error({
        message: error.replace(/<\/?[^>]+(>|$)/g, ''),
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <h2 style={{ margin: '0' }}>List of forums</h2>
      </div>
    );
  }

  function handleRemovePost(record) {
    dispatch(removePost(record._id));
  }

  function handleRemoveComment(record) {
    dispatch(removeComment(record._id));
  }

  function handlePin(record) {
    dispatch(pinPodcast(record._id));
  }

  function expandedRowRender(data) {
    const columns = [
      { title: 'Comment', dataIndex: 'comment', key: 'comment' },
      { title: 'User', dataIndex: 'user', key: 'user', render: user => user?.username },
      {
        title: 'Remove comment',
        key: 'actionsComment',
        dataIndex: 'actions',
        width: '120',
        render: (actions, record) =>
          !record.deleted?.date ? (
            <span>
              <Popconfirm
                title="Are you sure to delete this comment?"
                onConfirm={() => handleRemoveComment(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  Remove comment
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              <Tag color="red">{`Deleted by ${record.deleted.by}`}</Tag>
            </>
          ),
      },
    ];
    return <Table columns={columns} dataSource={data?.comments.comments} pagination={false} />;
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      width: '250',
    },
    {
      title: 'Date of Creation',
      dataIndex: 'creationDate',
      key: 'date',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
      width: '180',
      render: date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => new Date(a.category) - new Date(b.category),
      width: '150',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      render: user => user?.username,
      width: '150',
    },
    {
      title: 'Pinned',
      dataIndex: 'pinned',
      key: 'pinned',
      render: pinned => <Tag color={pinned ? 'green' : 'grey'}>{pinned ? 'Pinned' : 'Unpinned'}</Tag>,
      width: '150',
    },
    {
      title: 'Action',
      key: 'actions',
      dataIndex: 'actions',
      width: '120',
      render: (actions, record) =>
        !record.deleted?.date ? (
          <span>
            <Popconfirm
              title={`Are you sure to  ${record.pinned ? 'unpin' : 'pin'} this post?`}
              onConfirm={() => handlePin(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                {record.pinned ? 'Unpin post' : 'Pin post'}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure to delete this post?"
              onConfirm={() => handleRemovePost(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                Remove
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Tag color="red">{`Deleted by ${record.deleted.by}`}</Tag>
          </>
        ),
    },
  ];

  return (
    <>
      {loadingPosts ? (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      ) : (
        <Table
          dataSource={posts}
          columns={columns}
          style={{ margin: 15 }}
          size="small"
          //title={() => header()}
          rowKey={row => row._id}
          expandable={{
            expandedRowRender: record => (
              <p
                style={{
                  margin: 0,
                }}
              >
                {expandedRowRender(record)}
              </p>
            ),
            rowExpandable: record => record.comments.total > 0,
          }}
          pagination={false}
        ></Table>
      )}
    </>
  );
};

export default TablePosts;
