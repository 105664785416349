import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, InputNumber, Radio, Space, Input, DatePicker, Typography } from 'antd';
import { ConnectWalletBtn } from './../../index';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { editPremintNFT } from '../../../redux/actions/nfts';
import Web3 from 'web3';
import mintingContractABI from '../../../services/web3/abis/ERC721.json';
import marketplaceContractABI from '../../../services/web3/abis/Marketplace.json';
import { MARKETPLACE_CONTRACT_ADDRESS, MIN_PERCENT_INCREMENT, MAX_PERCENT_TO_PAYOUT } from './../../../config/index';
import { useAddress } from '../../../context/PassportProvider';
import { approvalForAll, createListing } from '../../../utils/listingContractMethods';
import { validateMaxPercentToPayout } from '../../../utils/helpers';
import { NFTStatus, ListingType, NFTMarket } from '../../../utils/status';
import moment from 'moment';
import { NFTModalState } from '../../../utils/nft.data';
import { useNFT } from '../../../context/nft.context';

const web3 = new Web3(Web3.givenProvider);

const FormListing = () => {
  const {
    showModal,
    setStepsModalVisible,
    setStepBlockchainTx,
    setApproveTransHash,
    setBlockchainTxHash,
    setButton,
    listingAgainData,
    setListingType,
    listingType,
  } = useNFT();
  const [currency] = useState('ETH');
  const [tokensToList, setTokensToList] = useState();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { premintedNFT } = useSelector(({ nfts }) => nfts);
  const walletAddress = useAddress();

  const allMinted = !premintedNFT?.some(nft => nft.status !== NFTStatus.MINTED);

  useEffect(() => {
    const _tokenList = () => {
      const tokensToList =
        premintedNFT &&
        premintedNFT?.filter(
          nft =>
            nft.status === NFTStatus.MINTED ||
            nft.status === NFTStatus.APPROVING ||
            nft.status === NFTStatus.APPROVED ||
            nft.status === NFTStatus.LISTING ||
            nft.status === NFTStatus.FINALIZED
        );

      setTokensToList(tokensToList?.length && tokensToList);

      const hasEnglishAuction = premintedNFT?.some(nft => {
        const primaryMarketActiveListing = nft.listings.find(listing => listing.market === NFTMarket.PRIMARY_SALE);
        return primaryMarketActiveListing?.type === ListingType.ENGLISH_AUCTION;
      });

      hasEnglishAuction && setListingType(ListingType.ENGLISH_AUCTION);
      if (listingAgainData) {
        const primaryMarketActiveListing = listingAgainData.listings.find(
          listing => listing.market === NFTMarket.PRIMARY_SALE
        );
        form.setFieldsValue({
          listType: primaryMarketActiveListing.type,
          tokenID: parseInt(listingAgainData.contractTokenId),
          price: primaryMarketActiveListing.amount,
          extensionDuration: primaryMarketActiveListing.extensionDuration / 60,
        });
      }
    };
    premintedNFT?.length && _tokenList();
    return () => {
      setListingType();
    };
  }, [premintedNFT]);

  useEffect(() => {
    form.resetFields();
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  const formSuccess = async values => {
    showModal(NFTModalState.LIST);
    setStepsModalVisible(true);

    const mintContract = new web3.eth.Contract(mintingContractABI, premintedNFT[0].collectionRef.contractAddress);

    const marketplaceContract = new web3.eth.Contract(marketplaceContractABI, MARKETPLACE_CONTRACT_ADDRESS);
    /* TODO: Add the payoutAddress and % to DB */

    const lastestBlock = await web3.eth.getBlock('latest');
    const tokensToListSize = listingType === ListingType.ENGLISH_AUCTION ? 1 : tokensToList.length;
    let tokensToListFull = [];

    for (let i = 0; i < tokensToListSize; i++) {
      tokensToListFull.push({
        nftContract: tokensToList[i].collectionRef.contractAddress,
        editionsBundleId: tokensToList[i].editionsBundleId,
        tokenId: listingAgainData ? values.tokenID : tokensToList[i].contractTokenId,
        seller: walletAddress,
        extensionDuration: values.extensionDuration ? values.extensionDuration : 0,
        endTime: values.endTime ? lastestBlock.timestamp + moment(values.endTime).diff(moment(), 'seconds') : 0,
        bidder: '0x0000000000000000000000000000000000000000',
        fixedPrice: values.listType === ListingType.FIXED_PRICE,
        amount: web3.utils.toWei(values.price.toString(), 'ether'),
        minPercentIncrementInBasisPoints: web3.utils.toHex(web3.utils.toBN(MIN_PERCENT_INCREMENT)),
      });
    }

    try {
      let isApprovedForAll = await mintContract.methods
        .isApprovedForAll(walletAddress, MARKETPLACE_CONTRACT_ADDRESS)
        .call({ from: walletAddress });

      if (!isApprovedForAll) {
        setStepBlockchainTx(0);
        approvalForAll(mintContract, walletAddress, setStepBlockchainTx, setApproveTransHash, setStepsModalVisible);
      }

      createListing(
        dispatch,
        marketplaceContract,
        walletAddress,
        isApprovedForAll,
        tokensToListFull,
        setStepBlockchainTx,
        setBlockchainTxHash,
        editPremintNFT,
        setButton,
        setStepsModalVisible,
        showModal
      );
    } catch (err) {
      console.log('Error creating a listing:', err);
    }
  };

  const formFailed = error => {
    console.log('Error', error);
  };

  return tokensToList ? (
    <Form
      form={form}
      name="register"
      layout="vertical"
      onFinish={formSuccess}
      onFinishFailed={formFailed}
      scrollToFirstError
      style={{
        margin: '0 0 0 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <>
        {!listingType && <Typography.Title level={4}>{`Select type of listing:`}</Typography.Title>}
        {allMinted && (
          <Form.Item name="listType" rules={[{ required: true, message: 'Please select a type of sale.' }]}>
            <Radio.Group
              onChange={e => setListingType(e.target.value)}
              value={listingType}
              style={{
                display: 'flex',
              }}
            >
              <Radio value={ListingType.FIXED_PRICE}>Fixed price</Radio>
              <Radio value={ListingType.ENGLISH_AUCTION}>English auction</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {listingAgainData && (
          <Form.Item label={`Token ID`} name="tokenID">
            <InputNumber style={{ width: '100%' }} disabled={listingAgainData} />
          </Form.Item>
        )}
        {listingType === ListingType.FIXED_PRICE && (
          <>
            <Form.Item
              label={`Token price:`}
              name="price"
              style={{
                width: '100%',
              }}
              rules={[
                {
                  required: true,
                  message: 'Please add a price.',
                },
              ]}
            >
              <Input type="number" min={0} max={1000} prefix={currency} step="0.001" placeholder={'i.e. 0.05'} />
            </Form.Item>
          </>
        )}
        {listingType === ListingType.ENGLISH_AUCTION && (
          <>
            <div
              style={{
                display: 'flex',
                gap: '5px',
              }}
            >
              <Form.Item
                label={`Start price for the token ${tokensToList[0].edition.number}/${tokensToList[0].edition.total}:`}
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Please add a start price.',
                  },
                ]}
                style={{ width: '100%' }}
              >
                <Input type="number" prefix={currency} min={0} max={1000} step="0.001" placeholder={'i.e. 0.05'} />
              </Form.Item>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '5px',
              }}
            >
              <Form.Item
                label={'Ext duration (min):'}
                name="extensionDuration"
                rules={[
                  {
                    required: true,
                    message: 'Please add a extension duration!',
                  },
                ]}
                style={{ width: '100%' }}
              >
                <InputNumber min={1} max={30} placeholder={'i.e. 14'} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label={'End time:'}
                name="endTime"
                rules={[
                  {
                    required: true,
                    message: 'Please add an end time!',
                  },
                ]}
                style={{ width: '100%' }}
              >
                <DatePicker showTime placeholder={'i.e. 2022-12-12 23:59:59'} style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </>
        )}
        {listingType && (
          <>
            <Form.Item>
              <ConnectWalletBtn text={listingAgainData ? 'List for sale again' : 'List for sale'} />
            </Form.Item>
          </>
        )}
      </>
    </Form>
  ) : null;
};

export default FormListing;
