import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredAchievement } from "../../../redux/actions/achievements";
import { Form, Input, Button } from "antd";

const SearchbarAchievements = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { achievements } = useSelector(({ achievements }) => achievements);

  const onFinish = (values) => {
    dispatch(getFilteredAchievement(values, achievements));
  };

  const onReset = () => {
    form.resetFields();
    dispatch(getFilteredAchievement({ type: "" }, achievements));
  };

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{
          display: "flex",
          backgroundColor: "#fff",
          padding: 10,
          margin: "15px 15px 0 15px",
        }}
      >
        <Form.Item name="title" label="Achievement:" style={{ margin: "0" }}>
          <Input placeholder="Place enter" />
        </Form.Item>
        <Form.Item style={{ margin: "0 20px 0 auto" }}>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
        <Form.Item style={{ margin: "0" }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SearchbarAchievements;
