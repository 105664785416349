import { config, passport } from '@imtbl/sdk';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CUSTOM_NODE_ENV, MARKETPLACE_API_URL, PASSPORT_CONFIG } from '../config';
import { message } from 'antd';
import { loginUserPassport, logoutUserPassport } from '../services/passport';
import axios from 'axios';

const PassportContext = createContext();

const PassportProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [loadingPassport, setLoadingPassport] = useState(false);
  const [auth, setAuth] = useState(null);
  const [address, setAddress] = useState(null);
  const [noAccessModal, setNoAccessModal] = useState(false);
  const isNetworkEVM = PASSPORT_CONFIG.network === 'zkEVM';

  const passportInstance = new passport.Passport({
    baseConfig: new config.ImmutableConfiguration({
      environment: CUSTOM_NODE_ENV === 'production' ? config.Environment.PRODUCTION : config.Environment.SANDBOX,
    }),
    clientId: PASSPORT_CONFIG.clientId,
    redirectUri: PASSPORT_CONFIG.redirectUri,
    logoutRedirectUri: PASSPORT_CONFIG.logoutRedirectUri,
    audience: PASSPORT_CONFIG.audience,
    scope: PASSPORT_CONFIG.scope,
  });

  const loginCallbackEvm = async () => {
    await passportInstance.loginCallback();
  };

  const logoutPassport = async () => {
    const authSession = sessionStorage.getItem(PASSPORT_CONFIG.authSessionStorageKey);
    if (authSession) await passportInstance.logout();
  };

  const connectImx = async () => {
    const _provider = await passportInstance.connectImx();
    const address = await _provider.getAddress();

    const userPromises = [
      passportInstance.getUserInfo(),
      passportInstance.getIdToken(),
      passportInstance.getAccessToken(),
    ];

    const [userProfile, idToken, accessToken] = await Promise.all(userPromises);

    return { _provider, address, userProfile, idToken, accessToken };
  };

  const connectEvm = async () => {
    // first, get provider and addres, and only then get user info and tokens;
    // otherwise, promises will resolve to undefined
    const _provider = await passportInstance.connectEvm();
    const [address] = await _provider.request({
      method: 'eth_requestAccounts',
    });

    const userPromises = [
      passportInstance.getUserInfo(),
      passportInstance.getIdToken(),
      passportInstance.getAccessToken(),
    ];
    const [userProfile, idToken, accessToken] = await Promise.all(userPromises);

    return { _provider, address, userProfile, idToken, accessToken };
  };

  const linkWallet = useCallback(async () => {
    setLoadingPassport(true);
    try {
      const { idToken } = isNetworkEVM ? await connectEvm() : await connectImx();

      if (idToken) {
        const { data } = await loginUserPassport(idToken);

        message.success(`${data?.message}`, 2.5);
        localStorage.setItem('user', JSON.stringify(data.user));
        setAddress(data.user.publicAddress);
        setAuth(true);
      } else {
        console.log('Could not connect wallet');
        message.error(`Could not connect wallet`, 2.5);
        setNoAccessModal(true);
        await resetWallet();
      }
    } catch (error) {
      console.log('Could not connect wallet: ', { error });
      message.error(`${error?.response?.data?.message || error?.message}`, 2.5);
      setNoAccessModal(true);
      await resetWallet();
    }
    setLoadingPassport(false);
  }, []);

  const resetWallet = useCallback(async () => {
    try {
      setAddress(null);
      setAuth(false);
      await logoutUserPassport();
      await logoutPassport(); // this must be called the last
    } catch (error) {
      console.log(error);
    }
  }, []);

  const checkToken = async () => {
    try {
      const res = await axios.get(`${MARKETPLACE_API_URL}/checkToken`, {
        withCredentials: true,
      });
      if (res?.status === 200) {
        setAddress(res.data.address);
        setAuth(true);
      }
    } catch (err) {
      await resetWallet();
    }
  };

  // check token every hour (prevent this from being executed from the login pop up)
  useEffect(() => {
    if (!pathname.includes('redirect-handler')) {
      checkToken();
      setInterval(checkToken, 3600000);
    }
  }, []);

  return (
    <PassportContext.Provider
      value={{
        loginCallbackEvm,
        linkWallet,
        resetWallet,
        address,
        auth,
        loadingPassport,
        noAccessModal,
        setNoAccessModal,
      }}
    >
      {children}
    </PassportContext.Provider>
  );
};

export const usePassportContext = () => {
  return useContext(PassportContext);
};

export const useAddress = () => {
  const { address } = usePassportContext();
  return address;
};

export default PassportProvider;
