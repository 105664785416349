import React from 'react';
import { Button } from 'antd';
import { usePassportContext, useAddress } from '../../context/PassportProvider.js';

const ConnectWalletBtn = ({ text }) => {
  const { linkWallet } = usePassportContext();
  const walletAddress = useAddress();

  return (
    <>
      {walletAddress ? (
        <Button type="primary" htmlType="submit">
          {text}
        </Button>
      ) : (
        <Button type="primary" onClick={linkWallet} style={{ cursor: 'pointer' }}>
          Connect your wallet!
        </Button>
      )}
    </>
  );
};

export default ConnectWalletBtn;
