import { Link } from 'react-router-dom';
import { Table, Button, Popconfirm, Progress, Spin, notification, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPodcasts, removePodcast, cleanStore } from '../../../redux/actions/podcasts';
import moment from 'moment';

const { Column } = Table;

const TablePodcasts = () => {
  const dispatch = useDispatch();
  const [podcasts, setPodcasts] = useState();
  const { podcasts: podcastsData, podcastDeleted, error } = useSelector(({ podcasts }) => podcasts);
  useEffect(() => {
    setPodcasts(JSON.parse(JSON.stringify(podcastsData)));
  }, [podcastsData]);

  useEffect(() => {
    if (podcastDeleted) {
      dispatch(getPodcasts());
    }
  }, [podcastDeleted, dispatch]);
  useEffect(() => {
    dispatch(getPodcasts());
    dispatch(cleanStore());
    /* eslint-disable */
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notification.error({
        message: error.replace(/<\/?[^>]+(>|$)/g, ''),
      });
    }
  }, [error]);

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <Link to="/add/podcast" style={{ marginLeft: 'auto' }}>
          <Button type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Create a new podcast
          </Button>
        </Link>
      </div>
    );
  }

  function handleRemove(record) {
    console.log(record);
    dispatch(removePodcast(record._id));
  }

  function expandedRowRender(data) {
    const columns = [
      { title: 'Description', dataIndex: 'description', key: 'description' },
      { title: 'Url', dataIndex: 'url', key: 'url' },
      {
        title: 'Preview image',
        dataIndex: 'previewImage',
        key: 'previewImage',
        render: previewImage => <Image src={previewImage} />,
      },
    ];
    const rows = [
      {
        key: data._id,
        description: data.description,
        url: data.url,
        previewImage: data.previewImage,
      },
    ];

    return <Table columns={columns} dataSource={rows} pagination={false} />;
  }

  return (
    <>
      <Table
        dataSource={podcasts}
        style={{ margin: 15 }}
        size="small"
        title={() => header()}
        rowKey={row => row._id}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: [0],
        }}
        pagination={false}
      >
        <Column title="Title" dataIndex="title" key="title" width={250} />
        <Column
          title="Season"
          dataIndex="season"
          key="season"
          width={50}
          defaultSortOrder="ascend"
          sorter={(a, b) => Number(a.season) - Number(b.season)}
        />
        <Column title="Episode" dataIndex="episode" key="episode" width={50} />
        <Column title="Duration" dataIndex="duration" key="duration" width={50} />
        <Column
          title="Date of Creation"
          dataIndex="creationDate"
          key="date"
          defaultSortOrder="ascend"
          sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
          width={180}
          render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
        />
        <Column
          title="Action"
          key="actions"
          dataIndex="actions"
          width={120}
          render={(actions, record) => (
            <span>
              <Link to={`/edit/podcast/${record._id}`} style={{ margin: '0 10px 0 0', padding: '0' }} type="link">
                Edit
              </Link>
              <Popconfirm
                title="Are you sure to delete this podcast?"
                onConfirm={() => handleRemove(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  Remove
                </Button>
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </>
  );
};

export default TablePodcasts;
