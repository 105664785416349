import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, message, Popconfirm, Typography } from 'antd';
import { getSystemUsers, removeSystemUser, cleanStore } from '../../../redux/actions/systemUsers';
import 'antd/dist/antd.css';
import SearchbarSystemUser from './../searchbarSystemUser/SearchbarSystemUser';
import moment from 'moment';

const { Column } = Table;

const UsersTable = ({ data }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState(undefined);
  const { systemUsers, loadingRemoveUser, removedUser, metadata } = useSelector(({ systemUsers }) => systemUsers);

  useEffect(() => {
    dispatch(getSystemUsers(page, pageSize, user));
    dispatch(cleanStore());
    /* eslint-disable */
  }, [page, pageSize, user, removedUser]);
  /* eslint-enable */

  useEffect(() => {
    loadingRemoveUser && message.loading('Removing system user...', 0.5);
    removedUser?.status === 200 && message.success(removedUser.data.message, 2);
    removedUser?.status === 403 && message.error(`${removedUser?.data?.message}`, 2);
  }, [loadingRemoveUser, removedUser]);

  const handleRemove = record => {
    dispatch(removeSystemUser(record.publicAddress));
  };

  function showTotal(total) {
    return `Total ${total} items`;
  }

  function onShowSizeChange(page, pageSize) {
    setPage(page);
    setPageSize(pageSize);
  }

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <Link to="/add/systemUser" style={{ marginLeft: 'auto' }}>
          <Button type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Create a new user
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <>
      <SearchbarSystemUser setUser={setUser} setPage={setPage} />
      <Table
        dataSource={systemUsers}
        style={{ margin: 15 }}
        size="small"
        title={() => header()}
        rowKey="_id"
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30'],
          total: metadata && metadata.total,
          showTotal: showTotal,
          onChange: onShowSizeChange,
          current: metadata && metadata.page,
        }}
      >
        <Column title="Email" dataIndex="email" key="email" ellipsis={true} />
        <Column title="Username" dataIndex="username" key="username" ellipsis={true} />

        <Column
          title="Wallet Address"
          dataIndex="publicAddress"
          key="walletAddress"
          ellipsis={true}
          render={(text, record) => (
            <Typography.Paragraph copyable={{ text }} style={{ padding: '0' }}>{` ${text?.slice(0, 6)}...${text?.slice(
              -5
            )}`}</Typography.Paragraph>
          )}
        />

        <Column
          title="Date of Creation"
          dataIndex="creationDate"
          key="date"
          defaultSortOrder="ascend"
          sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
          render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
        />

        <Column
          title="Action"
          key="actions"
          dataIndex="actions"
          render={(actions, record) => (
            <span>
              <Link
                to={`/edit/systemUser/${record.publicAddress}`}
                style={{ margin: '0 10px 0 0', padding: '0' }}
                type="link"
              >
                Edit
              </Link>
              <Popconfirm
                title="Are you sure to delete this collection?"
                onConfirm={() => handleRemove(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  Remove
                </Button>
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </>
  );
};

export default UsersTable;
