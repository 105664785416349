import React from 'react';
import { Form, Button, Typography, Result } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import { usePassportContext } from '../../context/PassportProvider';

const Signin = () => {
  const { linkWallet, noAccessModal, setNoAccessModal, loadingPassport } = usePassportContext();

  return (
    <div
      className="site-card-border-less-wrapper"
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Form
        name="normal_login"
        className="login-form"
        onFinish={linkWallet}
        style={{
          margin: 'auto',
          border: '1px solid #d9d9d9',
          padding: '20px',
          width: noAccessModal ? '500px' : '350px',
        }}
      >
        {!noAccessModal ? (
          <>
            <ControlOutlined
              style={{
                fontSize: '64px',
                margin: '0 auto 30px',
                display: 'block',
              }}
            />
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              FORTUN3
            </Typography.Title>
            <Form.Item>
              <Button
                loading={loadingPassport}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%' }}
              >
                Connect your account
              </Button>
            </Form.Item>
          </>
        ) : (
          <Result
            status="403"
            title=""
            subTitle={
              <>
                <p>It looks like you’re not in the allow list to test WTD’s NFT marketplace whitelabel solution. </p>
                <p>Check the following link and fill the form to gain access.</p>
              </>
            }
            extra={
              <Button
                href="https://docs.google.com/forms/d/e/1FAIpQLSfw0Kn8pbgPc4HJpnpnkes_LLwb2__VWTLhWouHLvkC2Xv24g/viewform"
                target="_blank"
                without
                rel="noreferrer"
                type="primary"
                onClick={() => setNoAccessModal(false)}
              >
                I want access
              </Button>
            }
          />
        )}
      </Form>
    </div>
  );
};

export default Signin;
