import React from 'react';
import {Form, Input, Button} from 'antd';

const SearchbarUser = ({setUser, setPage}) => {
	const [form] = Form.useForm();

	const onReset = () => {
		form.resetFields();
		setUser(undefined);
		setPage(1);
	};

	return (
		<>
			<Form
				form={form}
				name='control-hooks'
				onFinish={(values) => {
					setUser(values.walletAddress);
					setPage(1);
				}}
				style={{
					display: 'flex',
					backgroundColor: '#fff',
					padding: 10,
					margin: '15px 15px 0 15px',
				}}
			>
				<Form.Item
					name='walletAddress'
					label='Wallet address:'
					style={{margin: '0'}}
				>
					<Input placeholder='Place enter' />
				</Form.Item>

				<Form.Item style={{margin: '0 20px 0 auto'}}>
					<Button htmlType='button' onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
				<Form.Item style={{margin: '0'}}>
					<Button type='primary' htmlType='submit'>
						Search
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default SearchbarUser;
