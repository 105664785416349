import axios from 'axios';
import { ActionTypes } from '../../utils/constants';
import { MARKETPLACE_API_URL } from '../../config';

export const getPosts = (page, pageSize) => {
  return async dispatch => {
    dispatch(getPostsRequest());
    try {
      const response = await axios.get(`${MARKETPLACE_API_URL}/posts/${page}/${pageSize}/null`);
      return dispatch(getPostsSuccess(response.data.data.posts));
    } catch (err) {
      return dispatch(getPostsFailure(err?.response?.data));
    }
  };
};

export const getPost = id => {
  return async dispatch => {
    dispatch(getPostRequest());
    try {
      const response = await axios.get(`${MARKETPLACE_API_URL}/post/${id}/null`, { withCredentials: true });
      return dispatch(getPostSuccess(response.data));
    } catch (err) {
      return dispatch(getPostFailure(err?.response?.data));
    }
  };
};

export const pinPodcast = id => {
  return async dispatch => {
    dispatch(pinPostRequest());
    try {
      const response = await axios.put(`${MARKETPLACE_API_URL}/post/pin/${id}`, {}, { withCredentials: true });
      return dispatch(pinPostSuccess(response.data));
    } catch (err) {
      return dispatch(pinPostFailure(err?.response?.data));
    }
  };
};

export const removePost = id => {
  return async dispatch => {
    dispatch(removePostRequest());
    try {
      const response = await axios.delete(`${MARKETPLACE_API_URL}/post/admin/${id}`, { withCredentials: true });
      return dispatch(removePostSuccess(response.data));
    } catch (err) {
      return dispatch(removePostFailure(err?.response?.data));
    }
  };
};

export const removeComment = id => {
  return async dispatch => {
    dispatch(removeCommentRequest());
    try {
      const response = await axios.delete(`${MARKETPLACE_API_URL}/comment/admin/${id}`, { withCredentials: true });
      return dispatch(removeCommentSuccess(response.data));
    } catch (err) {
      return dispatch(removeCommentFailure(err?.response?.data));
    }
  };
};

const getPostsRequest = () => {
  return {
    type: ActionTypes.GET_POSTS_REQUEST,
  };
};

const getPostsSuccess = payload => {
  return {
    type: ActionTypes.GET_POSTS_SUCCESS,
    payload,
  };
};

const getPostsFailure = payload => {
  return {
    type: ActionTypes.GET_POSTS_FAILURE,
    payload,
  };
};

const getPostRequest = () => {
  return {
    type: ActionTypes.GET_POST_REQUEST,
  };
};

const getPostSuccess = payload => {
  return {
    type: ActionTypes.GET_POST_SUCCESS,
    payload,
  };
};

const getPostFailure = payload => {
  return {
    type: ActionTypes.PIN_POST_FAILURE,
    payload,
  };
};

const pinPostRequest = () => {
  return {
    type: ActionTypes.PIN_POST_REQUEST,
  };
};

const pinPostSuccess = payload => {
  return {
    type: ActionTypes.PIN_POST_SUCCESS,
    payload,
  };
};

const pinPostFailure = payload => {
  return {
    type: ActionTypes.REMOVE_POST_FAILURE,
    payload,
  };
};

const removePostRequest = () => {
  return {
    type: ActionTypes.REMOVE_POST_REQUEST,
  };
};

const removePostSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_POST_SUCCESS,
    payload,
  };
};

const removePostFailure = payload => {
  return {
    type: ActionTypes.REMOVE_POST_FAILURE,
    payload,
  };
};

const removeCommentRequest = () => {
  return {
    type: ActionTypes.REMOVE_COMMENT_REQUEST,
  };
};

const removeCommentSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_COMMENT_SUCCESS,
    payload,
  };
};

const removeCommentFailure = payload => {
  return {
    type: ActionTypes.REMOVE_COMMENT_FAILURE,
    payload,
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};
