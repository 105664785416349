import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Layout, Typography, message, PageHeader, Spin } from 'antd';
import 'antd/dist/antd.css';
import { getUsers, cleanStore, editUser } from '../../../redux/actions/users';
import { formItemLayout } from '../../../utils/helpers';

const { Content } = Layout;
const { Text } = Typography;

const UsersEditForm = () => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem('user'));
  const [form] = Form.useForm();
  const { users, loadingUserEdit, userEdit } = useSelector(({ users }) => users);

  useEffect(() => {
    dispatch(getUsers(1, 10, pathname.split('/edit/user/')[1]));
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    loadingUserEdit && message.loading('Editing user...', 1);

    userEdit?.status === 200 &&
      message
        .success(userEdit.data.message, 2.5)
        .then(() => form.resetFields(), dispatch(cleanStore()), history.push('/list/users'));

    userEdit?.status === 403 && message.error(`${userEdit?.data?.message}`, 2);
    /* eslint-disable */
  }, [loadingUserEdit, userEdit]);
  /* eslint-enable */

  const handleEditUser = values => {
    const data = {
      ...values,
      username: values.username ? values.username : users[0].username,
      publicAddress: users[0].publicAddress,
      email: values.email ? values.email : users[0].email,
      creator: user?.email,
      creationDate: users[0].creationDate,
    };

    dispatch(editUser(pathname.split('/edit/user/')[1], data));
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        overflow: 'auto',
        padding: 20,
        margin: 20,
        minHeight: 280,
        backgroundColor: 'White',
      }}
    >
      {users ? (
        <Form {...formItemLayout} form={form} name="register" onFinish={handleEditUser}>
          <PageHeader
            onBack={() => window.history.back()}
            title={users[0].email ? `${users[0].email}` : 'Edit user'}
            style={{ padding: '0' }}
          />

          <Form.Item name="publicAddress" label="Wallet address">
            <Text>{users[0].publicAddress}</Text>
          </Form.Item>

          <Form.Item name="username" label="Username">
            <Input placeholder={users[0].username} />
          </Form.Item>

          <Form.Item name="email" label="E-mail">
            <Input placeholder={users[0].email} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Edit user
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      )}
    </Content>
  );
};

export default UsersEditForm;
