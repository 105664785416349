import { Link } from 'react-router-dom';
import { Table, Button, Popconfirm, Progress, Spin, notification, Tag, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getNotifications,
  removeNotification,
  cleanStore,
  sendNotification,
} from '../../../redux/actions/notifications';
import moment from 'moment';

const { Column } = Table;

const TableNotifications = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState();
  const {
    notifications: notificationsData,
    notificationDeleted,
    notificationSent,
    error,
    loadingNotifications,
  } = useSelector(({ notifications }) => notifications);

  useEffect(() => {
    setNotifications(JSON.parse(JSON.stringify(notificationsData)));
  }, [notificationsData]);

  useEffect(() => {
    if (notificationDeleted || notificationSent) {
      dispatch(getNotifications());
    }
  }, [notificationDeleted, notificationSent, dispatch]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error?.message,
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <Link to="/add/notification" style={{ marginLeft: 'auto' }}>
          <Button type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Create a new notification
          </Button>
        </Link>
      </div>
    );
  }

  function handleRemove(record) {
    dispatch(removeNotification(record._id));
  }

  function handleSendNotification(record) {
    dispatch(sendNotification(record._id));
  }

  function expandedRowRender(data) {
    console.log(data);
    const columns = [
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        render: image => <Image src={image} />,
      },
      { title: 'Read by', dataIndex: 'readBy', key: 'readBy', render: readBy => (readBy ? readBy.length : '-') },
    ];
    const rows = [
      {
        key: data._id,
        description: data.description,
        image: data.image,
        readBy: data.readBy,
      },
    ];
    return <Table columns={columns} dataSource={rows} pagination={false} />;
  }

  return (
    <>
      <Table
        dataSource={notifications}
        style={{ margin: 15 }}
        size="small"
        title={() => header()}
        rowKey={row => row._id}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: [0] }}
        pagination={false}
      >
        <Column
          title="Date of Creation"
          dataIndex="creationDate"
          key="date"
          defaultSortOrder="ascend"
          sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
          width={180}
          render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
        />
        <Column
          title="Title"
          dataIndex="title"
          key="title"
          defaultSortOrder="ascend"
          sorter={(a, b) => a?.title?.localeCompare(b?.title)}
          width={250}
        />

        <Column
          title="Status"
          key="sent"
          width="20%"
          dataIndex="sent"
          render={sent => <Tag color={sent ? 'green' : 'red'}>{sent ? 'Sent' : 'Not sent'}</Tag>}
        />
        <Column
          title="Action"
          key="actions"
          dataIndex="actions"
          width={120}
          render={(actions, record) =>
            !record.sent ? (
              <span>
                <Popconfirm
                  title="Are you sure to send this notification?"
                  onConfirm={() => handleSendNotification(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                    Send
                  </Button>
                </Popconfirm>
                <Link
                  to={`/edit/notification/${record._id}`}
                  style={{ margin: '0 10px 0 0', padding: '0' }}
                  type="link"
                >
                  Edit
                </Link>
                <Popconfirm
                  title="Are you sure to delete this notification?"
                  onConfirm={() => handleRemove(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                    Remove
                  </Button>
                </Popconfirm>
              </span>
            ) : null
          }
        />
      </Table>
    </>
  );
};

export default TableNotifications;
