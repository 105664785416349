import axios from 'axios';
import { MARKETPLACE_API_URL } from '../../config';
import { ActionTypes } from '../../utils/constants';

const baseUrl = `${MARKETPLACE_API_URL}/poll`;

export const getPolls = () => {
  return async dispatch => {
    dispatch(getPollsRequest());
    try {
      const response = await axios.get(baseUrl, { withCredentials: true });
      return dispatch(getPollsSuccess(response.data));
    } catch (err) {
      return dispatch(getPollsFailure(err?.response?.data));
    }
  };
};

export const getPoll = id => {
  return async dispatch => {
    dispatch(getPollRequest());
    try {
      const response = await axios.get(`${baseUrl}/${id}`, { withCredentials: true });
      return dispatch(getPollSuccess(response.data));
    } catch (err) {
      return dispatch(getPollFailure(err?.response?.data));
    }
  };
};

export const editPoll = (id, pollData) => {
  return async dispatch => {
    dispatch(editPollRequest());
    try {
      const response = await axios.put(`${baseUrl}/${id}`, pollData, { withCredentials: true });
      return dispatch(editPollSuccess(response.data));
    } catch (err) {
      return dispatch(editPollFailure(err?.response?.data));
    }
  };
};

export const addPoll = pollData => {
  return async dispatch => {
    dispatch(addPollRequest());
    try {
      const response = await axios.post(baseUrl, pollData, { withCredentials: true });
      return dispatch(addPollSuccess(response.data));
    } catch (err) {
      return dispatch(addPollFailure(err?.response?.data));
    }
  };
};

export const removePoll = id => {
  return async dispatch => {
    dispatch(removePollRequest());
    try {
      const response = await axios.delete(`${baseUrl}/${id}`, { withCredentials: true });
      return dispatch(removePollSuccess(response.data));
    } catch (err) {
      return dispatch(removePollFailure(err?.response?.data));
    }
  };
};

const getPollsRequest = () => {
  return {
    type: ActionTypes.GET_POLLS_REQUEST,
  };
};

const getPollsSuccess = payload => {
  return {
    type: ActionTypes.GET_POLLS_SUCCESS,
    payload,
  };
};

const getPollsFailure = payload => {
  return {
    type: ActionTypes.GET_POLLS_FAILURE,
    payload,
  };
};

const getPollRequest = () => {
  return {
    type: ActionTypes.GET_POLL_REQUEST,
  };
};

const getPollSuccess = payload => {
  return {
    type: ActionTypes.GET_POLL_SUCCESS,
    payload,
  };
};

const getPollFailure = payload => {
  return {
    type: ActionTypes.GET_POLL_FAILURE,
    payload,
  };
};

const editPollRequest = () => {
  return {
    type: ActionTypes.UPDATE_POLL_REQUEST,
  };
};

const editPollSuccess = payload => {
  return {
    type: ActionTypes.UPDATE_POLL_SUCCESS,
    payload,
  };
};

const editPollFailure = payload => {
  return {
    type: ActionTypes.UPDATE_POLL_FAILURE,
    payload,
  };
};

const addPollRequest = () => {
  return {
    type: ActionTypes.ADD_POLL_REQUEST,
  };
};

const addPollSuccess = payload => {
  return {
    type: ActionTypes.ADD_POLL_SUCCESS,
    payload,
  };
};

const addPollFailure = payload => {
  return {
    type: ActionTypes.ADD_POLL_FAILURE,
    payload,
  };
};

const removePollRequest = () => {
  return {
    type: ActionTypes.REMOVE_POLL_REQUEST,
  };
};

const removePollSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_POLL_SUCCESS,
    payload,
  };
};

const removePollFailure = payload => {
  return {
    type: ActionTypes.REMOVE_POLL_FAILURE,
    payload,
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};
