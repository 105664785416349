import NFTProvider from '../../../context/nft.context';
import TableNFTContent from './TableNFTContent.component';

const TableNFT = () => {
  return (
    <NFTProvider>
      <TableNFTContent />
    </NFTProvider>
  );
};

export default TableNFT;
