import {ActionTypes} from '../../utils/constants';

const initialState = {
	systemUsers: null,
	loadingUserAll: false,

	userAdded: [],
	loadingAddUser: false,

	removedUser: [],
	loadingRemoveUser: false,

	userEdit: [],
	loadingUserEdit: false,

	metadata: null,
};

const systemUsers = (state = initialState, {type, payload}) => {
	switch (type) {
		/* Add user to DB */
		case ActionTypes.ADD_SYSTEM_USER_REQUEST:
			return {
				...state,
				loadingAddUser: true,
			};
		case ActionTypes.ADD_SYSTEM_USER_SUCCESS:
			return {
				...state,
				loadingAddUser: false,
				userAdded: payload,
			};
		case ActionTypes.ADD_SYSTEM_USER_FAILURE:
			return {
				...state,
				loadingAddUser: false,
				userAdded: payload,
			};

		/* Remove user from DB*/
		case ActionTypes.REMOVE_SYSTEM_USER_REQUEST:
			return {
				...state,
				loadingRemoveUser: true,
			};
		case ActionTypes.REMOVE_SYSTEM_USER_SUCCESS:
			return {
				...state,
				loadingRemoveUser: false,
				removedUser: payload,
			};
		case ActionTypes.REMOVE_SYSTEM_USER_FAILURE:
			return {
				...state,
				loadingRemoveUser: false,
				removedUser: payload,
			};

		/* Get all Userss from DB */
		case ActionTypes.GET_ALL_SYSTEM_USERS_REQUEST:
			return {
				...state,
				loadingUserAll: true,
			};
		case ActionTypes.GET_ALL_SYSTEM_USERS_SUCCESS:
			return {
				...state,
				loadingUserAll: false,
				systemUsers: payload,
			};
		case ActionTypes.GET_ALL_SYSTEM_USERS_FAILURE:
			return {
				...state,
				loadingUserAll: false,
			};

		/* Metadata */
		case ActionTypes.SET_SYSTEM_USERS_METADATA:
			return {
				...state,
				metadata: payload,
			};

		/* Edit user from DB*/
		case ActionTypes.EDIT_SYSTEM_USER_REQUEST:
			return {
				...state,
				loadingUserEdit: true,
			};
		case ActionTypes.EDIT_SYSTEM_USER_SUCCESS:
			return {
				...state,
				loadingUserEdit: false,
				userEdit: payload,
			};
		case ActionTypes.EDIT_SYSTEM_USER_FAILURE:
			return {
				...state,
				loadingUserEdit: false,
				userEdit: payload,
			};

		case ActionTypes.CLEAN_STORE:
			return {
				...state,
				userAdded: null,
				removedUser: null,
				userEdit: null,
			};

		default:
			return state;
	}
};

export default systemUsers;
