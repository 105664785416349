import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Layout, message, PageHeader, Spin, Typography } from 'antd';
import 'antd/dist/antd.css';
import { cleanStore, updateAchievement, getAchievement } from '../../../redux/actions/achievements';
import { formItemLayout } from '../../../utils/helpers';
import { AchievementTitles } from '../../../utils/achievements.data';

const { Content } = Layout;
const { Text } = Typography;

const AchievementForm = () => {
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { loadingAchievementEdit, achievementGet, achievementEdit } = useSelector(({ achievements }) => achievements);

  useEffect(() => {
    dispatch(getAchievement(pathname.split('/edit/achievement/')[1]));
    /* eslint-disable */
  }, []);

  useEffect(() => {
    loadingAchievementEdit && message.loading('Editing achievement...', 1);
    if (achievementEdit?.status === 200) {
      message.success(achievementEdit, 2.5);
      form.resetFields();
      dispatch(cleanStore());
      history.push('/list/achievements');
    }

    /* eslint-disable */
  }, [loadingAchievementEdit, achievementEdit]);
  /* eslint-enable */

  const formSuccess = values => {
    const data = {
      ...values,
    };
    dispatch(updateAchievement(pathname.split('/edit/achievement/')[1], data));
  };

  const formFailed = error => {
    console.log('Error', error);
  };

  return (
    <div>
      {' '}
      <Content
        className="site-layout-background"
        style={{
          overflow: 'auto',
          padding: 20,
          margin: 20,
          minHeight: 280,
          backgroundColor: 'White',
        }}
      >
        {achievementGet ? (
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={formSuccess}
            onFinishFailed={formFailed}
            scrollToFirstError
          >
            <PageHeader
              className="site-page-header"
              onBack={() => window.history.back()}
              title=" "
              style={{ padding: '0' }}
            />
            <Form.Item name="type" label="Type" initialValue={achievementGet.type}>
              <Text>{AchievementTitles[achievementGet.type]}</Text>
            </Form.Item>
            <Form.Item
              name="label"
              label="Label"
              initialValue={achievementGet.label}
              rules={[
                {
                  required: true,
                  message: 'Please input a label to display the achievement!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="New achievement label here" />
            </Form.Item>
            <Form.Item
              name="shortDescription"
              label="Short description"
              initialValue={achievementGet.shortDescription}
              rules={[
                {
                  required: true,
                  message: 'Please input a short description!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="A short description is needed (e.g.: Traded NFTs 5 times)" />
            </Form.Item>
            <Form.Item
              name="longDescription"
              label="Long description"
              initialValue={achievementGet.longDescription}
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea placeholder="Some description for the achievement here">
                {achievementGet.longDescription}
              </Input.TextArea>
            </Form.Item>
            <Form.Item name="requiredAmount" label="Quantity" initialValue={achievementGet.requiredAmount}>
              <Text>{achievementGet.requiredAmount}</Text>
            </Form.Item>
            <Form.Item name="points" label="Achievement points" initialValue={achievementGet.points}>
              <Text>{achievementGet.points}</Text>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Update achievement
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
        )}
      </Content>
    </div>
  );
};

export default AchievementForm;
