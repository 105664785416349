import axios from 'axios';
import { MARKETPLACE_API_URL } from './../config/index.js';

var Web3 = require('web3');

// "Web3.providers.givenProvider" will be set if in an Ethereum supported browser.
var web3 = new Web3();

export const getFileExtension = fileEvent => {
  if (!fileEvent?.file?.type) return '';
  return fileEvent.file.type.split('/')[1];
};

export const uploadImage = ({ onSuccess, onError, file }) => {
  if (file) {
    const url = `${MARKETPLACE_API_URL}/nft/pinFileToIPFS`;
    const data = new FormData();
    data.append('file', file);
    axios
      .post(url, data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary= ${data._boundary}`,
        },
        withCredentials: true,
      })
      .then(function (response) {
        onSuccess('Ok', response.data.IpfsHash);
      })
      .catch(function (error) {
        onError({ error });
      });
  }
};

export const removeImage = ({ xhr }) => {
  const url = `${MARKETPLACE_API_URL}/nft/unpinPinata/${xhr}`;
  return axios
    .delete(url, { withCredentials: true })
    .then(function (response) {
      console.log('File removed successfully: ', response);
    })
    .catch(function (error) {
      console.error('There was an error removing your file: ', error);
    });
};

export const validateContractAddress = (_, value, callback) => {
  if (!value) {
    callback();
    return;
  }
  const exist = value && web3.utils.isAddress(value);
  if (!exist) {
    callback('Add a valid contract address!');
  } else {
    callback();
  }
};

export const validateURL = (rule, value, callback) => {
  if (!value.includes(['http'])) {
    callback('Url must start with http: // or https: //');
  } else {
    callback();
  }
};

export const formItemLayout = {
  labelCol: {
    xs: {
      span: 0,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 0,
    },
    sm: {
      span: 10,
    },
  },
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 0,
      offset: 0,
    },
    sm: {
      span: 0,
      offset: 8,
    },
  },
};

export const firstCapitaLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const validateMaxPercentToPayout = (payoutAddress, callback, maximumPercentage) => {
  if (!payoutAddress) {
    callback('Please add at least one payout address.');
    return;
  }

  const payoutAddressSum = payoutAddress.reduce((a, b) => ({
    percentage: parseInt(a?.percentage) + parseInt(b?.percentage),
  }));

  if (payoutAddressSum?.percentage > maximumPercentage) {
    callback(`The sum of the percentages cannot be higher than ${maximumPercentage}%`);
    return;
  }
  callback();
};
