import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, message, Popconfirm } from 'antd';
import { getAchievements, removeAchievement, cleanStore, switchAchievement } from '../../../redux/actions/achievements';
import 'antd/dist/antd.css';
import moment from 'moment';
import { AchievementTitles, AchievementTypes } from '../../../utils/achievements.data';
const { Column } = Table;

const TableAchievements = () => {
  const dispatch = useDispatch();
  const {
    achievements,
    filteredAchievements,
    loadingRemovedAchievement,
    removedAchievement,
    loadingAchievementEdit,
    achievementEdit,
  } = useSelector(({ achievements }) => achievements);

  useEffect(() => {
    dispatch(getAchievements());
    dispatch(cleanStore());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadingRemovedAchievement && message.loading('Removing achievement...', 0.5);

    removedAchievement?.status === 200 &&
      dispatch(getAchievements()).then(() => {
        message.success(`${removedAchievement?.data.message}`, 2);
        dispatch(cleanStore());
      });

    removedAchievement?.status === 500 && message.warning(`${removedAchievement?.data.message}`);

    // eslint-disable-next-line
  }, [loadingRemovedAchievement, removedAchievement]);

  useEffect(() => {
    loadingAchievementEdit && message.loading('Switching achievement...', 0.5);

    achievementEdit?.status === 200 &&
      dispatch(getAchievements()).then(() => {
        message.success(`${achievementEdit?.data.message}`, 2);
        dispatch(cleanStore());
      });

    achievementEdit?.status === 500 && message.warning(`${achievementEdit?.data.message}`);

    // eslint-disable-next-line
  }, [loadingAchievementEdit, achievementEdit]);

  const handleRemove = record => {
    dispatch(removeAchievement(record));
  };

  const handleSwitch = record => {
    dispatch(switchAchievement(record));
  };

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <Link to="/add/achievement" style={{ marginLeft: 'auto' }}>
          <Button className="btn-create-achievement" type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Create a new achievement
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <>
      <Table
        className="list-of-achievements"
        dataSource={filteredAchievements.length > 0 ? filteredAchievements : achievements}
        style={{ margin: 15 }}
        size="middle"
        title={() => header()}
        rowKey="_id"
      >
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          width="12%"
          ellipsis={true}
          render={(text, record) => {
            const type = Object.values(AchievementTypes).find(achievementType => achievementType === text);
            return <span style={{ padding: '0' }}>{AchievementTitles[type]}</span>;
          }}
        />
        <Column
          title="Quantity"
          dataIndex="requiredAmount"
          key="requiredAmount"
          width="10%"
          ellipsis={true}
          render={(text, record) => <span style={{ padding: '0' }}>{text}</span>}
        />
        <Column title="Points" dataIndex="points" key="points" width="10%" ellipsis={true} />

        <Column title="Label" dataIndex="label" key="label" ellipsis={true} />
        <Column title="Short description" dataIndex="shortDescription" key="shortDescription" ellipsis={true} />
        <Column title="Long description" dataIndex="longDescription" key="longDescription" ellipsis={true} />

        <Column
          title="Status"
          dataIndex="active"
          key="active"
          ellipsis={true}
          render={active => <span style={{ padding: '0' }}>{active ? 'Active' : 'Inactive'}</span>}
        />

        <Column
          title="Date of Creation"
          dataIndex="creationDate"
          key="creationDate"
          defaultSortOrder="descend"
          width="15%"
          sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
          render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
        />

        <Column
          title="Action"
          key="actions"
          width="15%"
          dataIndex="actions"
          render={(actions, record) => (
            <span>
              <Link to={`/edit/achievement/${record._id}`} style={{ margin: '0 10px 0 0', padding: '0' }} type="link">
                Edit
              </Link>
              <Popconfirm
                title={`Are you sure you want to ${record.active ? 'switch off' : 'activate'} this achievement?`}
                onConfirm={() => handleSwitch(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  {record.active ? 'Switch off' : 'Activate'}
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to delete this achievement?"
                onConfirm={() => handleRemove(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  Remove
                </Button>
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </>
  );
};

export default TableAchievements;
