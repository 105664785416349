import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Card } from 'antd';

const CardNFT = () => {
  const { premintedNFT } = useSelector(({ nfts }) => nfts);

  return (
    <Card
      style={{
        width: 330,
        height: 'auto',
        marginRight: '30px',
      }}
      cover={
        <img alt="example" style={{ maxHeight: 200, objectFit: 'contain' }} src={`${premintedNFT[0]?.imgCDNURL}`} />
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text strong>{premintedNFT[0]?.name}</Typography.Text>
        <Typography.Text type="secondary" style={{ marginBottom: '10px' }}>
          {premintedNFT[0]?.collectionRef?.name} - {premintedNFT[0]?.edition?.total} Editions.
        </Typography.Text>
        <Typography.Paragraph
          ellipsis={{
            rows: 3,
            expandable: true,
          }}
        >
          {premintedNFT[0]?.description}
        </Typography.Paragraph>
      </div>
    </Card>
  );
};

export default CardNFT;
