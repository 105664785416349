import axios from 'axios';
import { MARKETPLACE_API_URL } from '../config';

export const loginUserPassport = idToken => {
  const configRequest = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };
  return axios.post(`${MARKETPLACE_API_URL}/admin/login-passport`, {}, configRequest);
};

export const logoutUserPassport = () => {
  return axios.get(`${MARKETPLACE_API_URL}/logout`, {
    withCredentials: true,
  });
};
