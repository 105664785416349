import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Layout, PageHeader, Spin, Space, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getPodcast, cleanStore, editPodcast, addPodcast } from '../../../redux/actions/podcasts';
import { uploadImage, removeImage, formItemLayout } from './../../../utils/helpers';

const { Content } = Layout;

const PodcastForm = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    podcast: podcastData,
    podcastAddedOrUpdated,
    error,
    loadingPodcast,
  } = useSelector(({ podcasts }) => podcasts);
  const [podcast, setPodcast] = useState();
  const [defaultFileList, setDefaultFileList] = useState([]);
  /* eslint-disable-next-line */
  const [id] = useState(pathname.split('/edit/podcast/')[1]);

  useEffect(() => {
    // Make a copy of the Podcast data to use it on this component
    // and avoid mutating the state
    if (podcastData) setPodcast(JSON.parse(JSON.stringify(podcastData)));
  }, [podcastData]);

  useEffect(() => {
    if (podcastAddedOrUpdated) {
      history.push('/list/podcasts');
    }
  }, [podcastAddedOrUpdated, history]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notification.error({
        message: typeof error === 'string' ? error.replace(/<\/?[^>]+(>|$)/g, '') : JSON.stringify(error),
      });
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(getPodcast(id));
    } else {
      setPodcast({
        season: null,
        episode: null,
        title: null,
        duration: null,
        url: null,
      });
    }

    return () => {
      dispatch(cleanStore());
    };
  }, [dispatch, pathname, id]);

  async function formSuccess(v) {
    const values = await form.validateFields();
    const data = {
      ...values,
      previewImage: values.previewImage && `https://ipfs.io/ipfs/${values.previewImage[0].xhr}`,
    };

    if (id) {
      dispatch(editPodcast(id, { ...podcast, ...data }));
    } else {
      dispatch(addPodcast(data));
    }
  }

  const formFailed = error => {
    console.log('Error', error);
  };

  const normFile = e => {
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        overflow: 'auto',
        padding: 20,
        margin: 20,
        minHeight: 280,
        backgroundColor: 'White',
      }}
    >
      {!loadingPodcast && podcast ? (
        <Form {...formItemLayout} form={form} name="register" onFinish={formSuccess} onFinishFailed={formFailed}>
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={id ? `Podcast #${id}` : ''}
            style={{ padding: '0 0 16px 0' }}
          />
          <Form.Item
            name="season"
            label="Season"
            initialValue={podcast.season}
            rules={[
              {
                required: true,
                message: 'Please input the season!',
              },
            ]}
          >
            <Input placeholder="Season here." />
          </Form.Item>
          <Form.Item
            name="episode"
            label="Episode"
            initialValue={podcast.episode}
            rules={[
              {
                required: true,
                message: 'Please input the episode!',
              },
            ]}
          >
            <Input placeholder="Episode here." />
          </Form.Item>
          <Form.Item
            name="title"
            label="Title"
            initialValue={podcast.title}
            rules={[
              {
                required: true,
                message: 'Please input the title!',
              },
            ]}
          >
            <Input placeholder="Title here." />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            initialValue={podcast.description}
            rules={[
              {
                required: true,
                message: 'Please input the description!',
              },
            ]}
          >
            <Input.TextArea placeholder="Some description for the podcast here" />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            initialValue={podcast.duration}
            rules={[
              {
                required: true,
                message: 'Please input the duration!',
              },
            ]}
          >
            <Input placeholder="Duration here." />
          </Form.Item>
          <Form.Item
            name="url"
            label="URL"
            initialValue={podcast.url}
            rules={[
              {
                required: true,
                message: 'Please input the URL!',
              },
            ]}
          >
            <Input placeholder="URL here." />
          </Form.Item>

          <Form.Item
            name="previewImage"
            label="Preview Image:"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={uploadImage}
              getValueFromEvent={normFile}
              onRemove={removeImage}
              className="image-upload-grid"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {id ? 'Edit Podcast' : 'Add Podcast'}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      )}
    </Content>
  );
};

export default PodcastForm;
