import React from 'react';
import { Button, Modal, Steps } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NETWORK_SCAN, NETWORK_SCAN_NAME } from '../../../config/index';
import { NFTModalState } from '../../../utils/nft.data';
import { useNFT } from '../../../context/nft.context';

const ModalTransactionSteps = () => {
  const {
    modal,
    showModal,
    setStepsModalVisible,
    stepsModalVisible,
    stepBlockchainTx,
    setStepBlockchainTx,
    blockchainTxHash,
    setBlockchainTxHash,
    approveTransHash,
    setApproveTransHash,
    button,
    setButton,
  } = useNFT();
  const history = useHistory();
  const { premintedNFT } = useSelector(({ nfts }) => nfts);

  const handleOpenModal = () => {
    if (modal === NFTModalState.MINT) {
      history.push('/list/nfts');
    }

    if ([NFTModalState.LIST, NFTModalState.LIST_AGAIN].includes(modal)) {
      setStepBlockchainTx(null);
      setApproveTransHash();
      setBlockchainTxHash();
      setButton(true);
    }
    showModal(false);
    setStepsModalVisible(false);
    /* form.resetFields(); */
  };

  const handleCloseModal = () => {
    if ([NFTModalState.LIST, NFTModalState.LIST_AGAIN].includes(modal)) {
      setApproveTransHash(null);
    }
    setBlockchainTxHash();
    setStepsModalVisible(false);
    showModal(false);
  };

  return (
    <Modal
      title={`${modal && modal} ${premintedNFT && premintedNFT[0]?.name}`}
      visible={stepsModalVisible}
      onOk={handleOpenModal}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button key="goToNFTs" type="primary" onClick={handleOpenModal} disabled={button}>
          Go to NFTs table.
        </Button>,
      ]}
    >
      {[NFTModalState.LIST, NFTModalState.LIST_AGAIN].includes(modal) ? (
        <Steps direction="vertical" size="small" current={stepBlockchainTx}>
          <Steps.Step
            title="Waiting for user confirmation"
            description={
              approveTransHash && (
                <a href={`${NETWORK_SCAN}tx/${approveTransHash}`} target="_blank" rel="noreferrer">
                  View on {NETWORK_SCAN_NAME}
                </a>
              )
            }
          />
          <Steps.Step title={stepBlockchainTx < 2 ? 'Approving this item for sale' : 'Approved for sale'} />
          <Steps.Step
            title="Waiting for user confirmation"
            description={
              blockchainTxHash && (
                <a href={`${NETWORK_SCAN}tx/${blockchainTxHash}`} target="_blank" rel="noreferrer">
                  View on {NETWORK_SCAN_NAME}
                </a>
              )
            }
          />
          <Steps.Step title={stepBlockchainTx < 4 ? 'Listing this item for sale' : 'Listed for sale'} />
          <Steps.Step title={stepBlockchainTx < 5 ? 'Processing transaction' : 'Transaction confirmed'} />
        </Steps>
      ) : modal === NFTModalState.MINT ? (
        <Steps direction="vertical" size="small" current={stepBlockchainTx}>
          <Steps.Step
            title="Waiting for user confirmation"
            description={
              blockchainTxHash && (
                <a href={`${NETWORK_SCAN}tx/${blockchainTxHash}`} target="_blank" rel="noreferrer">
                  View on {NETWORK_SCAN_NAME}
                </a>
              )
            }
          />
          <Steps.Step title="Waiting for blockchain confirmation" />
          <Steps.Step title={stepBlockchainTx === 2 ? 'Transaction confirmed' : 'Processing transaction'} />
        </Steps>
      ) : null}
    </Modal>
  );
};

export default ModalTransactionSteps;
