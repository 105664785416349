import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Joyride from 'react-joyride';
import { useBackoffice } from './../../../context/BackofficeContext';
import { Form, Input, Button, Select, Upload, Switch, InputNumber, Layout, message, Space, PageHeader } from 'antd';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import {
  uploadImage,
  removeImage,
  formItemLayout,
  tailFormItemLayout,
  getFileExtension,
} from './../../../utils/helpers';
import { ConnectWalletBtn } from './../../index';
import { premintNFT, cleanStore } from '../../../redux/actions/nfts';
import { getCollections } from '../../../redux/actions/collections';
import tooltip from './../../../utils/tooltips.json';
import moment from 'moment';

const { Content } = Layout;

const PreMintingForm = () => {
  const { tooltips, setTooltips } = useBackoffice();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [defaultFileList, setDefaultFileList] = useState([]);
  const { loadingPreMint, NFTPreMinted } = useSelector(({ nfts }) => nfts);
  const { collections } = useSelector(({ collections }) => collections);

  const [state] = useState({
    run: tooltips,
    continuous: true,
    steps: [
      {
        target: '.collection-item-form',
        content:
          'Once all the data in your NFT has been completed, the newly created collection will appear in this field.',
        disableBeacon: true,
        /* disableOverlayClose: true, */
        hideCloseButton: true,
      },
      {
        content: 'Click here to see your preminted NFT.',

        disableBeacon: true,
        /* disableOverlayClose: true, */
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: '.table-nfts',
        title: 'Ready!',
      },
    ],
  });

  const [fileExtension, setFileExtension] = useState('');
  const handleGetCollections = () => {
    dispatch(getCollections());
  };

  useEffect(() => {
    loadingPreMint && message.loading('Creating NFT...', 1.5);

    NFTPreMinted?.status === 201 && message.success(`${NFTPreMinted.data.message}`, 3.5);

    NFTPreMinted?.status === 200 && form.resetFields();
    NFTPreMinted?.status === 200 &&
      message
        .success(`${NFTPreMinted.data.message}`, 3.5)
        .then(() => setDefaultFileList([]))
        .then(() => dispatch(cleanStore()), history.push('/list/nfts'));

    NFTPreMinted?.status === 404 && message.error(`${NFTPreMinted?.data?.message}`, 2);
    /* eslint-disable */
  }, [loadingPreMint]);
  /* eslint-enable */

  const formSuccess = values => {
    const data = {
      ...values,
      creationDate: moment(new Date()).format(),
      redeemable: values?.redeemable === 'checked' ? true : false,
      imgCDNURL: values.imgCDNURL && `${values.imgCDNURL[0].xhr}`,
      owner: null, // Owner should start as null since we don't want to save brand related wallets
      fileExtension,
    };
    dispatch(premintNFT(data));
  };

  const formFailed = error => {
    console.log('Error', error);
  };

  const normFile = e => {
    setFileExtension(getFileExtension(e));
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleTooltip = data => {
    if (data.action === 'close' || data.action === 'reset') {
      setTooltips(false);
    }
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        overflow: 'auto',
        padding: 20,
        margin: 20,
        minHeight: 280,
        backgroundColor: 'White',
      }}
    >
      {tooltips && (
        <Joyride
          callback={data => handleTooltip(data)}
          steps={state.steps}
          run={state.run}
          continuous={state.continuous}
        />
      )}
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={formSuccess}
        onFinishFailed={formFailed}
        initialValues={{
          redeemable: 'checked',
        }}
        scrollToFirstError
      >
        <PageHeader
          className="site-page-header"
          onBack={() => window.history.back()}
          title=" "
          style={{ padding: '0' }}
        />
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input the NFT name!',
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="New NFT name here." />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Some description for the NFT here." />
        </Form.Item>

        <Form.Item
          name="imgCDNURL"
          label="Multimedia:"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload
            accept="image/*"
            customRequest={uploadImage}
            onChange={normFile}
            onRemove={removeImage}
            className="image-upload-grid"
            listType="picture"
          >
            {defaultFileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
          </Upload>
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[{ required: true, message: 'Please select a quantity' }]}
          tooltip={tooltip?.nft.quantity}
        >
          <InputNumber defaultValue={0} min={1} />
        </Form.Item>

        <Form.Item label="Token properties" style={{ marginBottom: 0 }} tooltip={tooltip?.nft.tokenProperties}>
          <Form.List name="metadata">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      fieldKey={[fieldKey, 'key']}
                      rules={[{ required: true, message: 'Missing key' }]}
                    >
                      <Input placeholder="Key" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      fieldKey={[fieldKey, 'value']}
                      rules={[{ required: true, message: 'Missing value' }]}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add token properties
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item name="redeemable" label="Redeemable" valuePropName="checked" tooltip={tooltip?.nft.redeemable}>
          <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
        </Form.Item>

        <Form.Item
          name="collectionRef"
          label="Collection:"
          rules={[
            {
              required: true,
              message: 'Please sellect one!',
              whitespace: true,
            },
          ]}
        >
          <Select
            className="collection-item-form"
            onMouseEnter={() => handleGetCollections()}
            placeholder={'Select a collection.'}
          >
            {collections?.map(collection => (
              <Select.Option value={collection?._id}>{collection?.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <ConnectWalletBtn text="Premint collectible" />
        </Form.Item>
      </Form>
    </Content>
  );
};

export default PreMintingForm;
