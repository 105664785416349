import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, Upload, Switch, Layout, message, Image, PageHeader, Space, Spin } from 'antd';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { uploadImage, removeImage, formItemLayout, tailFormItemLayout } from '../../../utils/helpers';
import { getPremintedNFT, editPremintNFT, cleanStore } from '../../../redux/actions/nfts';
import { getCollections } from '../../../redux/actions/collections';

const { Content } = Layout;

const EditNFTForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let { pathname } = useLocation();
  const history = useHistory();
  const [defaultFileList, setDefaultFileList] = useState([]);
  const { premintedNFT, loadingEditNFT, editedNFT } = useSelector(({ nfts }) => nfts);
  const { collections } = useSelector(({ collections }) => collections);

  const handleGetCollections = () => {
    dispatch(getCollections());
  };

  useEffect(() => {
    dispatch(getPremintedNFT(pathname.split('/edit/nft/')[1]));
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    loadingEditNFT && message.loading('Editing NFT...', 1.5);

    editedNFT?.status === 200 &&
      message
        .success(`${editedNFT.data.message}`, 3.5)
        .then(() => form.resetFields(), setDefaultFileList([]), dispatch(cleanStore()), history.push('/list/nfts'));

    editedNFT?.status === 404 && message.error(`${editedNFT?.data?.message}`, 2);

    /* eslint-disable */
  }, [loadingEditNFT, editedNFT]);
  /* eslint-enable */

  const formSuccess = values => {
    const data = {
      ...values,
      collectionRef: values.collectionRefEdit,
      imgCDNURL: values.imgCDNURLEdit ? `${values.imgCDNURLEdit[0].xhr}` : values.imgCDNURL,
    };

    dispatch(editPremintNFT(pathname.split('/edit/nft/')[1], 'all', data));
  };

  const formFailed = error => {
    console.log('Error', error);
  };

  const normFile = e => {
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        overflow: 'auto',
        padding: 20,
        margin: 20,
        minHeight: 280,
        backgroundColor: 'White',
      }}
    >
      {premintedNFT ? (
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={formSuccess}
          onFinishFailed={formFailed}
          initialValues={premintedNFT && premintedNFT[0]}
          scrollToFirstError
        >
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={premintedNFT[0]?.name}
            style={{ padding: '0' }}
          />
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input the NFT name!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="New NFT name here." />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Some description for the NFT here." />
          </Form.Item>
          {defaultFileList.length === 0 && (
            <Form.Item name="imgCDNURL" label="Multimedia:">
              {premintedNFT[0]?.imgCDNURL ? <Image height={150} src={`${premintedNFT[0]?.imgCDNURL}`} /> : 'No image'}
            </Form.Item>
          )}
          <Form.Item name="imgCDNURLEdit" label="Multimedia:" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload
              accept="image/*"
              customRequest={uploadImage}
              onChange={normFile}
              onRemove={removeImage}
              className="image-upload-grid"
              listType="picture"
            >
              {defaultFileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
            </Upload>
          </Form.Item>
          <Form.Item label="Token properties" style={{ marginBottom: 0 }}>
            <Form.List name="metadataCDNURL">
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex' }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'key']}
                        fieldKey={[fieldKey, 'key']}
                        rules={[{ required: true, message: 'Missing key' }]}
                      >
                        <Input placeholder="Key" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'value']}
                        fieldKey={[fieldKey, 'value']}
                        rules={[{ required: true, message: 'Missing value' }]}
                      >
                        <Input placeholder="Value" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add token properties
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item name="redeemable" label="Redeemable" valuePropName="checked">
            <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
          </Form.Item>
          <Form.Item
            name="collectionRefEdit"
            label="Collections:"
            rules={[
              {
                required: true,
                message: 'Please select one!',
                whitespace: true,
              },
            ]}
          >
            <Select onMouseEnter={() => handleGetCollections()}>
              {collections?.map(collection => (
                <Select.Option value={collection?._id} key={collection?._id}>
                  {collection?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Edit NFT
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      )}
    </Content>
  );
};

export default EditNFTForm;
