import React from 'react';
import {PageHeader} from 'antd';

const Breadcrumbs = ({title}) => {
	return (
		<PageHeader
			className='site-page-header'
			title={title}
			style={{
				backgroundColor: 'White',
			}}
		/>
	);
};

export default Breadcrumbs;
