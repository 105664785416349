import React from 'react';
import Joyride from 'react-joyride';

export default class HowItWorks extends React.Component {
	state = {
		run: true,
		continuous: true,
		steps: [
			/* 	{
				content: (
					<div>
						You can interact with your own components through the spotlight.
						<br />
						Click the menu above!
					</div>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: 'bottom',
				spotlightClicks: true,
				styles: {
					options: {
						zIndex: 10000,
					},
				},
				target: '.table-collections',
				title: 'Menu',
			}, */

			{
				target: '.table-collections',
				content: 'This is my awesome feature!',
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
			},
			{
				target: '.table-nfts',
				content: 'This another awesome feature!',
			},
		],
	};

	render() {
		const {steps, run, continuous} = this.state;

		return (
			<div className='app'>
				<Joyride steps={steps} run={run} continuous={continuous} />
			</div>
		);
	}
}

/* const steps = [
	{
		title: 'Create a Colletion',
		content: 'Fisrt-content',
	},
	{
		title: 'Premint an NFT',
		content: 'Second-content',
	},
	{
		title: 'Mint an NFT',
		content: 'Thirt-content',
	},
	{
		title: 'List an NFT',
		content: 'Last-content',
	},
];
const HowItWorks = () => {
	const [current, setCurrent] = React.useState(0);

	return (
		<Content
			className='site-layout-background'
			style={{
				padding: 20,
				margin: 20,
				minHeight: 280,
				backgroundColor: 'White',
			}}
		>
			<Steps current={current}>
				{steps.map((item) => (
					<Step key={item.title} title={item.title} />
				))}
			</Steps>
			<div
				style={{
					minHeight: '200px',
					marginTop: '16px',
					textAlign: 'center',
					backgroundColor: '#fafafa',
					border: '1px dashed #e9e9e9',
					borderRadius: '2px',
				}}
			>
				{steps[current].content}
			</div>
			<div style={{marginTop: '24px'}}>
				{current > 0 && (
					<Button
						style={{margin: '0 8px'}}
						onClick={() => setCurrent(current - 1)}
					>
						Previous
					</Button>
				)}
				{current === 0 && (
					<Button style={{margin: '0 8px'}} disabled>
						Previous
					</Button>
				)}
				{current < steps.length - 1 && (
					<Button type='primary' onClick={() => setCurrent(current + 1)}>
						Next
					</Button>
				)}
			</div>
		</Content>
	);
};

export default HowItWorks;
 */
