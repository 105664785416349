export const AchievementTypes = {
  // FIRST_PURCHASE_PRIMARY: 'first_purchase_primary',
  // FIRST_LISTING: 'first_listing',
  NEW_MEMBERSHIP: 'new_membership',
  //FIRST_TRADE: 'first_trade',
  // FIRST_PURCHASE_SECONDARY: 'first_purchase_secondary',
  // FIRST_LISTING_POINTS: 'first_listing_points',
  // FIRST_PURCHASE_POINTS: 'first_purchase_points',
  // FIRST_LISTING_PASSPORT: 'first_listing_passport',
  // FIRST_PURCHASE_PASSPORT: 'first_purchase_passport',
  PROFILE_UPDATE: 'profile_update',
  EMAIL_NOTIFICATION: 'email_notification',
};

export const AchievementTitles = {
  // [AchievementTypes.FIRST_PURCHASE_PRIMARY]: 'NFT Purchase (Primary Market)',
  // [AchievementTypes.FIRST_LISTING]: 'NFT Listing',
  [AchievementTypes.NEW_MEMBERSHIP]: 'New membership',
  // [AchievementTypes.FIRST_TRADE]: 'NFT trade',
  // [AchievementTypes.FIRST_PURCHASE_SECONDARY]: 'NFT Purchase (Secondary Market)',
  // [AchievementTypes.FIRST_LISTING_POINTS]: 'Points listing',
  // [AchievementTypes.FIRST_PURCHASE_POINTS]: 'Points puchase',
  // [AchievementTypes.FIRST_LISTING_PASSPORT]: 'Passport listing',
  // [AchievementTypes.FIRST_PURCHASE_PASSPORT]: 'Passport purchase',
  [AchievementTypes.PROFILE_UPDATE]: 'Profile update',
  [AchievementTypes.EMAIL_NOTIFICATION]: 'Email notifications',
};

export const AchievementPoints = ['5', '10', '20', '50', '100', '500'];
