const data = {
  local: {
    marketplaceContractAddress: '0x174B9FF3ABC56C1BB7B3b6AB75C817Af6AA25826',
    backofficeAppURL: 'http://localhost:3001',
    marketplaceApiURL: 'http://localhost:4000',
    networkID: 11155111,
    networkScan: 'https://sepolia.etherscan.io/',
  },
  development: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    backofficeAppURL: 'https://fortun3-backoffice-dev.waytoodigital.com',
    marketplaceApiURL: 'https://fortun3-api-dev.waytoodigital.com',
    networkID: 11155111,
    networkScan: 'https://sepolia.etherscan.io/',
  },
  staging: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    backofficeAppURL: 'https://fortun3-backoffice-stg.waytoodigital.com',
    marketplaceApiURL: 'https://fortun3-api-stg.waytoodigital.com',
    networkID: 11155111,
    networkScan: 'https://sepolia.etherscan.io/',
  },
  production: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    backofficeAppURL: 'https://backoffice.fortun3.io',
    marketplaceApiURL: 'https://api.fortun3.io',
    networkID: 11155111,
    networkScan: 'https://sepolia.etherscan.io/',
  },
};

export const CUSTOM_NODE_ENV = process.env.REACT_APP_CUSTOM_NODE_ENV;
export const ONBOARD_API_KEY = process.env.REACT_APP_ONBOARD_API_KEY;
export const FORMATIC_API_KEY = process.env.REACT_APP_FORMATIC_API_KEY;

export const MARKETPLACE_CONTRACT_ADDRESS = data[CUSTOM_NODE_ENV].marketplaceContractAddress;
export const MARKETPLACE_API_URL = data[CUSTOM_NODE_ENV].marketplaceApiURL;
export const BACKOFFICE_APP_URL = data[CUSTOM_NODE_ENV].backofficeAppURL;
export const NETWORK_ID = data[CUSTOM_NODE_ENV].networkID;
export const NETWORK_SCAN = data[CUSTOM_NODE_ENV].networkScan;
export const NETWORK_SCAN_NAME = 'Snowtrace';

export const MIN_PERCENT_INCREMENT = process.env.REACT_APP_MIN_PERCENT_INCREMENT || 1;
export const MAX_PERCENT_TO_PAYOUT = process.env.REACT_APP_MAX_PERCENT_TO_PAYOUT || 20;

const PASSPORT_CLIENT_ID = process.env.REACT_APP_PASSPORT_CLIENT_ID;

export const PASSPORT_CONFIG = {
  clientAppURL: BACKOFFICE_APP_URL,
  redirectUri: `${BACKOFFICE_APP_URL}/redirect-handler`,
  logoutRedirectUri: `${BACKOFFICE_APP_URL}`,
  audience: 'platform_api',
  scope: 'openid offline_access email transact',
  clientId: PASSPORT_CLIENT_ID,
  authSessionStorageKey: `oidc.user:https://auth.immutable.com:${PASSPORT_CLIENT_ID}`,
  network: 'StarEx' /* zkEVM or IMX */,
};
