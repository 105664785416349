import React from 'react';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import ProtectedLogin from './ProtectedLogin/ProtectedLogin';
import PublicRoute from './PublicRoute/PublicRoute';
import { Switch } from 'react-router-dom';
import { Signin, Dashboard, RedirectHandler } from './../../../components';

const Routes = () => {
  return (
    <Switch>
      <PublicRoute path={'/redirect-handler'} component={RedirectHandler} />
      <ProtectedLogin exact path="/signin" component={Signin} />
      <PrivateRoute
        path={['/', '/premint', '/mint', '/list', '/listing', '/add', '/edit', '/create']}
        component={Dashboard}
      />
    </Switch>
  );
};

export default Routes;
