import axios from 'axios';
import { ActionTypes } from '../../utils/constants';
import { MARKETPLACE_API_URL } from '../../config';
import { message } from 'antd';

/* Add collection to DB */
export const createCollection = data => {
  const url = `${MARKETPLACE_API_URL}/collection`;

  return async dispatch => {
    dispatch(createCollectionRequest());
    try {
      const res = await axios.post(url, data, { withCredentials: true });
      return dispatch(createCollectionSuccess(res));
    } catch (err) {
      return dispatch(createCollectionFailure());
    }
  };
};
const createCollectionRequest = () => {
  return {
    type: ActionTypes.CREATE_COLLECTION_REQUEST,
  };
};
const createCollectionSuccess = payload => {
  return {
    type: ActionTypes.CREATE_COLLECTION_SUCCESS,
    payload,
  };
};
const createCollectionFailure = () => {
  return {
    type: ActionTypes.CREATE_COLLECTION_FAILURE,
  };
};

/* Import collection to DB */
export const importCollection = data => {
  const url = `${MARKETPLACE_API_URL}/importCollection`;

  return async dispatch => {
    dispatch(importCollectionRequest());
    try {
      const res = await axios.post(url, data, { withCredentials: true });
      return dispatch(importCollectionSuccess(res));
    } catch (err) {
      return dispatch(importCollectionFailure());
    }
  };
};
const importCollectionRequest = () => {
  return {
    type: ActionTypes.IMPORT_COLLECTION_REQUEST,
  };
};
const importCollectionSuccess = payload => {
  return {
    type: ActionTypes.IMPORT_COLLECTION_SUCCESS,
    payload,
  };
};
const importCollectionFailure = () => {
  return {
    type: ActionTypes.IMPORT_COLLECTION_FAILURE,
  };
};

/* Get a collection from DB */
export const getCollection = _id => {
  return async dispatch => {
    dispatch(getCollectionRequest());
    try {
      const { data } = await axios.get(`${MARKETPLACE_API_URL}/collection/${_id}`);
      return dispatch(getCollectionSuccess(data));
    } catch ({ message }) {
      return dispatch(getCollectionFailure());
    }
  };
};
const getCollectionRequest = () => {
  return {
    type: ActionTypes.GET_COLLECTION_REQUEST,
  };
};
const getCollectionSuccess = payload => {
  return {
    type: ActionTypes.GET_COLLECTION_SUCCESS,
    payload,
  };
};
const getCollectionFailure = () => {
  return {
    type: ActionTypes.GET_COLLECTION_FAILURE,
  };
};

/* Edit collection from DB*/
export const editCollection = (id, data) => {
  const url = `${MARKETPLACE_API_URL}/collection/${id}`;

  return async dispatch => {
    dispatch(editCollectionequest());
    try {
      const res = await axios.put(url, { data }, { withCredentials: true });
      return dispatch(editCollectionSuccess(res));
    } catch (err) {
      return dispatch(editCollectionFailure());
    }
  };
};
const editCollectionequest = () => {
  return {
    type: ActionTypes.EDIT_COLLECTION_REQUEST,
  };
};
const editCollectionSuccess = payload => {
  return {
    type: ActionTypes.EDIT_COLLECTION_SUCCESS,
    payload,
  };
};
const editCollectionFailure = () => {
  return {
    type: ActionTypes.EDIT_COLLECTION_FAILURE,
  };
};

/* Remove collection from DB*/
export const removeCollection = data => {
  const url = `${MARKETPLACE_API_URL}/collection/${data._id}`;

  return async dispatch => {
    dispatch(removeCollectionRequest());
    try {
      const res = await axios.delete(url, { withCredentials: true });
      return dispatch(removeCollectionSuccess(res));
    } catch (err) {
      return dispatch(removeCollectionFailure(err.response));
    }
  };
};
const removeCollectionRequest = () => {
  return {
    type: ActionTypes.REMOVE_COLLECTION_REQUEST,
  };
};
const removeCollectionSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_COLLECTION_SUCCESS,
    payload,
  };
};
const removeCollectionFailure = payload => {
  return {
    type: ActionTypes.REMOVE_COLLECTION_FAILURE,
    payload,
  };
};

/* Get all Collections */
export const getCollections = () => {
  const url = `${MARKETPLACE_API_URL}/collections`;
  return async dispatch => {
    dispatch(getCollectionsRequest());
    try {
      const { data } = await axios.get(url);
      return dispatch(getCollectionsSuccess(data));
    } catch (err) {
      message.error(`${err.response.data.message}`, 2);
      return dispatch(getCollectionsFailure());
    }
  };
};
const getCollectionsRequest = () => {
  return {
    type: ActionTypes.GET_COLLECTIONS_REQUEST,
  };
};
const getCollectionsSuccess = payload => {
  return {
    type: ActionTypes.GET_COLLECTIONS_SUCCESS,
    payload,
  };
};
const getCollectionsFailure = () => {
  return {
    type: ActionTypes.GET_COLLECTIONS_FAILURE,
  };
};

/* Get filter collections */
let filteredCollection;
export const getFilteredCollections = ({ name }, data) => {
  // Return collections filtered by name
  let filterByName = name ? data.filter(el => el.name.toLowerCase().includes(name.toLowerCase())) : null;
  return async dispatch => {
    filterByName ? (filteredCollection = filterByName) : (filteredCollection = data);
    return dispatch({
      type: ActionTypes.GET_FILTERED_COLLECTIONS,
      payload: filteredCollection,
    });
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};
