import {useState, createContext, useContext} from 'react';

let context = createContext();
let {Provider} = context;

export const useBackoffice = () => useContext(context);

export default function BackofficeProvider({children}) {
	const [tooltips, setTooltips] = useState(false);

	return (
		<Provider
			value={{
				tooltips,
				setTooltips,
			}}
		>
			{children}
		</Provider>
	);
}
