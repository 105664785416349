import { Modal, Steps } from 'antd';

const CustomModalTransactionsStep = ({
  visible,
  stepBlockchainTx,
  userAcceptedTXHash,
  footer,
  closable = false,
  title = '',
}) => {
  return (
    <Modal closable={closable} title={title} visible={visible} footer={footer}>
      <Steps direction='vertical' size='small' current={stepBlockchainTx}>
        <Steps.Step
          title='Waiting for user confirmation'
          description={
            userAcceptedTXHash && (
              <a
                href={`https://goerli.etherscan.io/tx/${userAcceptedTXHash}`}
                target='_blank'
                rel='noreferrer'
              >
                View on etherscan
              </a>
            )
          }
        />

        <Steps.Step
          title={
             'Waiting for blockchain confirmation'
          }
        />
        <Steps.Step
          title={'Finished transaction'}
        />
      </Steps>
    </Modal>
  );
};

export default CustomModalTransactionsStep;
