import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Upload, Layout, Typography, message, Image, PageHeader, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { cleanStore, getCollection, editCollection } from '../../../redux/actions/collections';
import { uploadImage, removeImage, formItemLayout } from '../../../utils/helpers';

const { Content } = Layout;
const { Text } = Typography;

const ColecctionEditForm = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [defaultFileList, setDefaultFileList] = useState([]);
  const { collectionGet, loadingCollectionEdit, collectionEdit } = useSelector(({ collections }) => collections);

  useEffect(() => {
    dispatch(getCollection(pathname.split('/edit/collection/')[1]));
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    loadingCollectionEdit && message.loading('Editing collection...', 1);

    if (collectionEdit?.status === 200) {
      message.success(collectionEdit.data, 2.5);
      form.resetFields();
      dispatch(cleanStore());
      history.push('/list/collections');
    }

    /* eslint-disable */
  }, [loadingCollectionEdit, collectionEdit]);
  /* eslint-enable */

  const formSuccess = values => {
    const data = {
      ...values,
      creator: user.email,
      imgCDNURL: values.imgCDNURLEdit ? `${values.imgCDNURLEdit[0].xhr}` : values.imgCDNURL,
    };

    dispatch(editCollection(pathname.split('/edit/collection/')[1], data));
  };

  const formFailed = error => {
    console.log('Error', error);
  };

  const normFile = e => {
    setDefaultFileList(e.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div>
      {' '}
      <Content
        className="site-layout-background"
        style={{
          overflow: 'auto',
          padding: 20,
          margin: 20,
          minHeight: 280,
          backgroundColor: 'White',
        }}
      >
        {collectionGet ? (
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={formSuccess}
            onFinishFailed={formFailed}
            initialValues={collectionGet}
            scrollToFirstError
          >
            <PageHeader
              className="site-page-header"
              onBack={() => window.history.back()}
              title={collectionGet?.name}
              style={{ padding: '0' }}
            />

            <Form.Item
              name="name"
              label="Collection Name"
              rules={[
                {
                  required: true,
                  message: 'Please input the collection name!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="New collection name here." />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input.TextArea placeholder="Some description for the collection here" />
            </Form.Item>

            <Form.Item name="contractAddress" label="Contract address:">
              <Text> {collectionGet?.contractAddress} </Text>
            </Form.Item>

            <Form.Item
              name="externalURL"
              label="URL:"
              rules={[
                {
                  required: true,
                  message: 'Please add the URL!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="https://example.com" />
            </Form.Item>
            {defaultFileList.length === 0 && (
              <Form.Item name="imgCDNURL" label="Multimedia:">
                <Image width={200} src={`${collectionGet?.imgCDNURL}`} />
              </Form.Item>
            )}
            <Form.Item name="imgCDNURLEdit" label="Multimedia:" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                accept="image/*"
                customRequest={uploadImage}
                onChange={normFile}
                onRemove={removeImage}
                className="image-upload-grid"
                listType="picture"
              >
                {defaultFileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
              </Upload>
            </Form.Item>

            <Form.Item name="startDate" label="Start date">
              <Text> {collectionGet?.startDate} </Text>
            </Form.Item>

            <Form.Item name="endDate" label="End date">
              <Text> {collectionGet?.endDate} </Text>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Edit collection
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
        )}
      </Content>
    </div>
  );
};

export default ColecctionEditForm;
