import { Route, Redirect } from 'react-router-dom';
import { usePassportContext } from '../../../../context/PassportProvider';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = usePassportContext();

  return <Route {...rest} render={() => (auth ? <Component /> : <Redirect to="/signin" />)} />;
};

export default PrivateRoute;
