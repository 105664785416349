import { ActionTypes } from '../../utils/constants';

const initialState = {
  posts: null,
  post: null,
  error: null,
  loadingPosts: false,
  loadingPost: false,
  postAddedOrUpdated: false,
  postDeleted: false,
};

const posts = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_POSTS_SUCCESS:
      return {
        ...state,
        loadingPosts: false,
        posts: payload,
      };

    case ActionTypes.GET_POSTS_FAILURE:
      return {
        ...state,
        loadingPosts: false,
        posts: null,
        error: payload,
      };

    case ActionTypes.GET_POSTS_REQUEST:
      return {
        ...state,
        loadingPosts: true,
      };

    case ActionTypes.GET_POST_REQUEST:
      return {
        ...state,
        loadingPost: true,
      };

    case ActionTypes.GET_POST_SUCCESS:
      return {
        ...state,
        loadingPost: false,
        post: payload,
      };

    case ActionTypes.GET_POST_FAILURE:
      return {
        ...state,
        loadingPost: false,
        post: null,
        error: payload,
      };

    case ActionTypes.PIN_POST_REQUEST:
      return {
        ...state,
        loadingPosts: true,
        postAddedOrUpdated: false,
      };

    case ActionTypes.PIN_POST_SUCCESS:
      return {
        ...state,
        loadingPost: false,
        post: payload,
        postAddedOrUpdated: true,
      };

    case ActionTypes.PIN_POST_FAILURE:
      return {
        ...state,
        loadingPost: false,
        error: payload,
        postAddedOrUpdated: false,
      };

    case ActionTypes.REMOVE_POST_REQUEST:
      return {
        ...state,
        loadingPosts: true,
        removedPost: null,
        postDeleted: false,
      };

    case ActionTypes.REMOVE_POST_SUCCESS:
      return {
        ...state,
        loadingPosts: false,
        removedPost: payload,
        postDeleted: true,
      };

    case ActionTypes.REMOVE_POST_FAILURE:
      return {
        ...state,
        loadingPosts: false,
        removedPost: null,
        error: payload,
        postDeleted: false,
      };

    case ActionTypes.REMOVE_COMMENT_REQUEST:
      return {
        ...state,
        loadingPosts: true,
        removedPost: null,
        postDeleted: false,
      };

    case ActionTypes.REMOVE_COMMENT_SUCCESS:
      return {
        ...state,
        loadingPosts: false,
        removedPost: payload,
        postDeleted: true,
      };

    case ActionTypes.REMOVE_COMMENT_FAILURE:
      return {
        ...state,
        loadingPosts: false,
        removedPost: null,
        error: payload,
        postDeleted: false,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        posts: null,
        post: null,
        removedPost: null,
        error: null,
        loadingPosts: false,
        postAddedOrUpdated: false,
        postDeleted: false,
      };

    default:
      return state;
  }
};

export default posts;
