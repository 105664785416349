import React from 'react';
import moment from 'moment';
import { Form, Button, Select, DatePicker, Input } from 'antd';
import { leaderboardTypes } from '../../../utils/leaderboards';
import { getLeaderboard } from '../../../context/leaderboards.actions';
const { RangePicker } = DatePicker;

const LeaderboardsBar = ({ dates, setDates, leaderboard, setLeaderboard, setData, setLoading }) => {
  const [form] = Form.useForm();

  const handleRange = value => {
    if (value === null) {
      setDates([null, null]);
    } else {
      setDates(value);
    }
  };

  const handlePreview = async ({ limit }) => {
    setLoading(true);
    const data = await getLeaderboard(leaderboard, dates, limit);
    setData({ ...leaderboardTypes[leaderboard.toUpperCase()], dates, content: data });
    setLoading(false);
  };

  const handleSelect = value => {
    setLeaderboard(value);
  };

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px',
          backgroundColor: '#fff',
          padding: 10,
          margin: '15px 15px 0 15px',
        }}
        onFinish={handlePreview}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          <Form.Item name="leaderboard" label="Leaderboard" style={{ margin: '0' }}>
            <Select
              defaultValue={leaderboardTypes.DIC_PUNCHES.id}
              options={[
                // { label: leaderboardTypes.OVERALL.label, value: leaderboardTypes.OVERALL.id },
                { label: leaderboardTypes.DIC_PUNCHES.label, value: leaderboardTypes.DIC_PUNCHES.id },
                { label: leaderboardTypes.STORY_FYBS.label, value: leaderboardTypes.STORY_FYBS.id },
                { label: leaderboardTypes.TRADES.label, value: leaderboardTypes.TRADES.id },
              ]}
              style={{ minWidth: '115px' }}
              onChange={handleSelect}
            />
          </Form.Item>
          <Form.Item style={{ margin: '0' }} initialValue={[null, moment()]}>
            <RangePicker defaultValue={[null, moment()]} allowEmpty={[true, false]} onChange={handleRange} />
          </Form.Item>
          <Form.Item name="limit" style={{ margin: '0' }}>
            <Input placeholder="Limit (optional)" type="number" style={{ width: '135px' }} />
          </Form.Item>
        </div>
        <Form.Item style={{ margin: '0' }}>
          <Button type="primary" htmlType="submit" disabled={!dates[1]}>
            Preview
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LeaderboardsBar;
