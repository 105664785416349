import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Input, Button, Layout, message, PageHeader} from 'antd';
import 'antd/dist/antd.css';
import {addSystemUser, cleanStore} from '../../../redux/actions/systemUsers';
import {formItemLayout, validateContractAddress} from '../../../utils/helpers';

const {Content} = Layout;

const UsersForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	let user = JSON.parse(localStorage.getItem('user'));
	const [form] = Form.useForm();
	const {loadingAddUser, userAdded} = useSelector(
		({systemUsers}) => systemUsers
	);

	useEffect(() => {
		loadingAddUser && message.loading('Creating user...', 1);
		userAdded?.status === 200 && form.resetFields();
		userAdded?.status === 200 &&
			message
				.success(`${userAdded?.data?.message}`, 2.5)
				.then(() => dispatch(cleanStore()), history.push('/list/systemUsers'));
		(userAdded?.status === 403 || userAdded?.status === 404) &&
			message
				.error(`${userAdded?.data?.message}`, 2.5)
				.then(() => dispatch(cleanStore()));

		/* eslint-disable */
	}, [loadingAddUser]);
	/* eslint-enable */

	const formSuccess = (values) => {
		const data = {
			...values,
			creator: user?.email,
		};

		dispatch(addSystemUser(data));
	};

	return (
		<div>
			{' '}
			<Content
				style={{
					overflow: 'auto',
					padding: 20,
					margin: 20,
					minHeight: 280,
					backgroundColor: 'White',
				}}
			>
				<Form
					{...formItemLayout}
					form={form}
					name='register'
					onFinish={formSuccess}
				>
					<PageHeader
						className='site-page-header'
						onBack={() => window.history.back()}
						title={' '}
						style={{padding: '0'}}
					/>
					<Form.Item
						name='email'
						label='E-mail'
						rules={[
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						]}
					>
						<Input placeholder='E-mail here.' />
					</Form.Item>

					<Form.Item
						name='username'
						label='Username'
						rules={[
							{
								required: true,
								message: 'Please input your username!',
							},
						]}
					>
						<Input placeholder='Username here.' />
					</Form.Item>

					<Form.Item
						name='publicAddress'
						label='Wallet address'
						rules={[
							{
								required: true,
								message: 'Please add the Wallet address!',
							},
							{validator: validateContractAddress},
						]}
					>
						<Input placeholder='Wallet address here.' />
					</Form.Item>

					<Form.Item wrapperCol={{offset: 7, span: 16}}>
						<Button type='primary' htmlType='submit'>
							Create users
						</Button>
					</Form.Item>
				</Form>
			</Content>
		</div>
	);
};

export default UsersForm;
