import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Button, message, Result, Spin } from 'antd';
import { editPremintNFT, getNFTs } from '../../../redux/actions/nfts';
import { ConnectWalletBtn, CardNFT } from './../../index';
import Web3 from 'web3';
import mintingContractABI from '../../../services/web3/abis/ERC721.json';
import { useAddress } from '../../../context/PassportProvider';
import { useNFT } from '../../../context/nft.context';
import { NFTModalState } from '../../../utils/nft.data';

const web3 = new Web3(Web3.givenProvider);

const { Content } = Layout;

const MintNFT = () => {
  const { showModal, setStepsModalVisible, setStepBlockchainTx, setBlockchainTxHash, setButton } = useNFT();
  const dispatch = useDispatch();
  const walletAddress = useAddress();

  const { premintedNFT } = useSelector(({ nfts }) => nfts);

  const handleMint = () => {
    mintNFT(walletAddress, premintedNFT);
    setStepsModalVisible(true);
    showModal(NFTModalState.MINT);
  };

  const mintNFT = async (walletAddress, premintedNFT) => {
    setStepBlockchainTx(null);
    const contract = new web3.eth.Contract(
      mintingContractABI,
      premintedNFT && premintedNFT[0].collectionRef.contractAddress
    );

    const nftsHashes = premintedNFT.map(el => el.metadataCDNURL);

    try {
      await contract.methods
        .mint(walletAddress, nftsHashes)
        .send({
          from: walletAddress,
        })
        .once('transactionHash', function (hash) {
          setStepBlockchainTx(0);
          setBlockchainTxHash(hash);
          dispatch(
            editPremintNFT(premintedNFT[0]?.editionsBundleId, 'all', {
              status: 'minting',
            })
          );
        })
        .once('receipt', function (receipt) {
          setStepBlockchainTx(1);
          dispatch(getNFTs());

          function confirmTransaction() {
            setStepBlockchainTx(2);
            setButton(false);
          }
          setTimeout(confirmTransaction, 3000);
        })
        .on('error', function (error, receipt) {
          dispatch(getNFTs());
          setStepsModalVisible(false);
          message.error(error.message, 3.5);
        });
    } catch (err) {
      console.log('Error', err);
    }
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '0 20px 0 20px',
        backgroundColor: 'White',
      }}
    >
      {premintedNFT ? (
        <div
          style={{
            display: 'flex',
          }}
        >
          <CardNFT />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              maxWidth: 450,
              padding: '0 20px 0 20px',
            }}
          >
            <Result
              style={{
                padding: '0',
                marginBottom: '20px',
              }}
              title={
                <>
                  <p style={{ fontSize: 15, margin: '0' }}>
                    {'You are going to mint an '}
                    <b>{`NFT of ${premintedNFT[0].edition.total} editions`}</b>{' '}
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      margin: '0',
                    }}
                  >{`with the contract: ${premintedNFT[0].collectionRef.contractAddress.slice(
                    0,
                    6
                  )}...${premintedNFT[0].collectionRef.contractAddress.slice(-5)}`}</p>
                </>
              }
            />
            <>
              {walletAddress ? (
                <Button type="primary" htmlType="submit" onClick={() => handleMint()}>
                  Mint collectible
                </Button>
              ) : (
                <ConnectWalletBtn />
              )}
            </>
          </div>
        </div>
      ) : (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      )}
    </Content>
  );
};

export default MintNFT;
