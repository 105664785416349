import axios from 'axios';
import { MARKETPLACE_API_URL } from '../../config';
import { ActionTypes } from '../../utils/constants';
import { message } from 'antd';
import { AchievementTitles } from '../../utils/achievements.data';

/* Add achiement to DB */
const createAchievementRequest = () => {
  return {
    type: ActionTypes.CREATE_ACHIEVEMENT_REQUEST,
  };
};

const createAchievementSuccess = payload => {
  return {
    type: ActionTypes.CREATE_ACHIEVEMENT_SUCCESS,
    payload,
  };
};

const createAchievementFailure = payload => {
  return {
    type: ActionTypes.CREATE_ACHIEVEMENT_FAILURE,
    payload,
  };
};

export const createAchievement = data => {
  const url = `${MARKETPLACE_API_URL}/achievement`;

  return async dispatch => {
    dispatch(createAchievementRequest());
    try {
      const res = await axios.post(url, data, { withCredentials: true });
      return dispatch(createAchievementSuccess(res));
    } catch (err) {
      return dispatch(createAchievementFailure(err.response));
    }
  };
};

/* Remove achievement from DB*/
const removeAchievementRequest = () => {
  return {
    type: ActionTypes.REMOVE_ACHIEVEMENT_REQUEST,
  };
};

const removeAchievementSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_ACHIEVEMENT_SUCCESS,
    payload,
  };
};

const removeAchievementFailure = payload => {
  return {
    type: ActionTypes.REMOVE_ACHIEVEMENT_FAILURE,
    payload,
  };
};

export const removeAchievement = data => {
  const url = `${MARKETPLACE_API_URL}/achievement/${data._id}`;

  return async dispatch => {
    dispatch(removeAchievementRequest());
    try {
      const res = await axios.delete(url, { withCredentials: true });
      return dispatch(removeAchievementSuccess(res));
    } catch (err) {
      return dispatch(removeAchievementFailure(err.response));
    }
  };
};

/* Edit achievement from DB*/
const editAchievementRequest = () => {
  return {
    type: ActionTypes.EDIT_ACHIEVEMENT_REQUEST,
  };
};

const editAchievementSuccess = payload => {
  return {
    type: ActionTypes.EDIT_ACHIEVEMENT_SUCCESS,
    payload,
  };
};

const editAchievementFailure = payload => {
  return {
    type: ActionTypes.EDIT_ACHIEVEMENT_FAILURE,
    payload,
  };
};

export const switchAchievement = data => {
  const url = `${MARKETPLACE_API_URL}/achievement/${data._id}`;

  return async dispatch => {
    dispatch(editAchievementRequest());
    try {
      const res = await axios.put(url, { active: !data.active }, { withCredentials: true });
      return dispatch(editAchievementSuccess(res));
    } catch (err) {
      return dispatch(editAchievementFailure(err.response));
    }
  };
};

export const updateAchievement = (id, data) => {
  const url = `${MARKETPLACE_API_URL}/achievement/${id}`;

  return async dispatch => {
    dispatch(editAchievementRequest());
    try {
      const res = await axios.put(
        url,
        {
          label: data.label,
          shortDescription: data.shortDescription,
          longDescription: data.longDescription,
        },
        { withCredentials: true }
      );
      return dispatch(editAchievementSuccess(res));
    } catch (err) {
      return dispatch(editAchievementFailure(err.response));
    }
  };
};

/* Get all achievements */
const getAchievementsRequest = () => {
  return {
    type: ActionTypes.GET_ACHIEVEMENTS_REQUEST,
  };
};

const getAchievementsSuccess = payload => {
  return {
    type: ActionTypes.GET_ACHIEVEMENTS_SUCCESS,
    payload,
  };
};

const getAchievementsFailure = () => {
  return {
    type: ActionTypes.GET_ACHIEVEMENTS_FAILURE,
  };
};

export const getAchievements = () => {
  const url = `${MARKETPLACE_API_URL}/achievements`;
  return async dispatch => {
    dispatch(getAchievementsRequest());
    try {
      const response = await axios.get(url, { withCredentials: true });
      return dispatch(getAchievementsSuccess(response.data.data));
    } catch (err) {
      message.error(`${err.response.data.message}`, 2);
      return dispatch(getAchievementsFailure());
    }
  };
};

/* Get achievement */
const getAchievementRequest = () => {
  return {
    type: ActionTypes.GET_ACHIEVEMENT_REQUEST,
  };
};

const getAchievementSuccess = payload => {
  return {
    type: ActionTypes.GET_ACHIEVEMENT_SUCCESS,
    payload,
  };
};

const getAchievementFailure = () => {
  return {
    type: ActionTypes.GET_ACHIEVEMENT_FAILURE,
  };
};

export const getAchievement = id => {
  const url = `${MARKETPLACE_API_URL}/achievement/${id}`;
  return async dispatch => {
    dispatch(getAchievementRequest());
    try {
      const { data } = await axios.get(url, { withCredentials: true });
      return dispatch(getAchievementSuccess(data.achievement));
    } catch (err) {
      return dispatch(getAchievementFailure(err));
    }
  };
};

/* Get filter achievements */
let filteredAchievement;
export const getFilteredAchievement = ({ title }, data) => {
  // Return achievements filtered by title
  let filterByName = title
    ? data.filter(el => AchievementTitles[el.type].toLowerCase().includes(title.toLowerCase()))
    : null;
  return async dispatch => {
    filterByName ? (filteredAchievement = filterByName) : (filteredAchievement = data);
    return dispatch({
      type: ActionTypes.GET_FILTERED_ACHIEVEMENTS,
      payload: filteredAchievement,
    });
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};
