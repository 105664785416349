import {message} from 'antd';
import {MARKETPLACE_CONTRACT_ADDRESS} from '../config/index';
import { getNFTs } from '../redux/actions/nfts';
import {NFTStatus} from '../utils/status';

export const approvalForAll = async (
	mintContract,
	walletAddress,
	setStepBlockchainTx,
	setApproveTransHash,
	setStepsModalVisible
) => {
	await mintContract.methods
		.setApprovalForAll(MARKETPLACE_CONTRACT_ADDRESS, true)
		.send({
			from: walletAddress,
		})
		.once('transactionHash', function (hash) {
			setStepBlockchainTx(1);
			setApproveTransHash(hash);
		})
		.once('receipt', function (receipt) {
			setStepBlockchainTx(2);
		})
		.on('error', function (error, receipt) {
			console.error(error);
			setStepsModalVisible(false);
			message.error(error.message, 3.5);
			return;
		});
};

export const createListing = async (
	dispatch,
	marketplaceContract,
	walletAddress,
	isApprovedForAll,
	tokensToListFull,
	setStepBlockchainTx,
	setBlockchainTxHash,
	editPremintNFT,
	setButton,
	setStepsModalVisible,
	showModal,
) => {
	await marketplaceContract.methods
		.batchCreateListing(tokensToListFull)
		.send({
			from: walletAddress,
		})
		.once('transactionHash', function (hash) {
			isApprovedForAll &&
				message.info('All tokens were previously approved', 3);
			setStepBlockchainTx(3);
			setBlockchainTxHash(hash);
			tokensToListFull.forEach(async(nft) =>
				await dispatch(
					editPremintNFT(nft.editionsBundleId, nft.contractTokenId, {
						status: NFTStatus.LISTING,
					})
				)
			);
		})
		.once('receipt', async function (receipt) {
			setStepBlockchainTx(4);
			showModal(false);
			setStepsModalVisible(false);
			dispatch(getNFTs());
			function confirmTransaction() {
				setStepBlockchainTx(5);
				setButton(false);
			}
			setTimeout(confirmTransaction, 2000);
		})
		.on('error', async function (error, receipt) {
			showModal(false);
			setStepsModalVisible(false);
			console.error(error);
			dispatch(getNFTs());
			message.error(error.message, 3.5);
			return;
		});
};
