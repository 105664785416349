import React from 'react';
import { Button, Table } from 'antd';
import {
  columns,
  downloadCSVLeaderboard,
  getLeaderboardDataSource,
  laederboardCSVMaker,
} from '../../../utils/leaderboards';

const PreviewTable = ({ data, loading }) => {
  // update column title according to selected leaderboard
  columns[columns.length - 1].title = data.lastColumnTitle;

  const handleExport = () => {
    const dataSource = getLeaderboardDataSource(data);
    const csv = laederboardCSVMaker(columns, dataSource);
    const start = data.dates[0] === null ? 'start' : data.dates[0].format('L');
    const end = data.dates[1].format('L');
    const periodString = `from_${start}_to_${end}`;
    const fileName = `${data.id}_${periodString}`;
    downloadCSVLeaderboard(csv, fileName);
  };

  const Header = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0' }}>
        <h2 style={{ margin: '0' }}>{data.label}</h2>
        <Button type="primary" style={{ display: 'flex', alignItems: 'center' }} onClick={handleExport}>
          Export CSV
        </Button>
      </div>
    );
  };

  return (
    <Table
      dataSource={getLeaderboardDataSource(data)}
      columns={data.content.length > 0 ? columns : []}
      style={{ margin: 15 }}
      title={data.content.length > 0 ? Header : null}
      loading={loading}
    />
  );
};

export default PreviewTable;
