import { Route, Redirect } from 'react-router-dom';
import { usePassportContext } from '../../../../context/PassportProvider';

const ProtectedLogin = ({ component: Component, ...rest }) => {
  const { auth } = usePassportContext();

  return <Route {...rest} render={() => (!auth ? <Component /> : <Redirect to="/" />)} />;
};

export default ProtectedLogin;
