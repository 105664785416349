import { ActionTypes } from '../../utils/constants';

const initialState = {
  podcasts: null,
  podcast: null,
  error: null,
  loadingPodcasts: false,
  loadingPodcast: false,
  podcastAddedOrUpdated: false,
  podcastDeleted: false,
};

const podcasts = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_PODCASTS_SUCCESS:
      return {
        ...state,
        loadingPodcasts: false,
        podcasts: payload,
      };

    case ActionTypes.GET_PODCASTS_FAILURE:
      return {
        ...state,
        loadingPodcasts: false,
        podcasts: null,
        error: payload,
      };

    case ActionTypes.GET_PODCASTS_REQUEST:
      return {
        ...state,
        loadingpPodcasts: true,
      };

    case ActionTypes.GET_PODCAST_REQUEST:
      return {
        ...state,
        loadingPodcast: true,
      };

    case ActionTypes.GET_PODCAST_SUCCESS:
      return {
        ...state,
        loadingPodcast: false,
        podcast: payload,
      };

    case ActionTypes.GET_PODCAST_FAILURE:
      return {
        ...state,
        loadingPodcast: false,
        podcast: null,
        error: payload,
      };

    case ActionTypes.UPDATE_PODCAST_REQUEST:
      return {
        ...state,
        loadingPodcast: true,
        podcastAddedOrUpdated: false,
      };

    case ActionTypes.UPDATE_PODCAST_SUCCESS:
      return {
        ...state,
        loadingPodcast: false,
        podcast: payload,
        podcastAddedOrUpdated: true,
      };

    case ActionTypes.UPDATE_PODCAST_FAILURE:
      return {
        ...state,
        loadingPodcast: false,
        payload: null,
        error: payload,
        podcastAddedOrUpdated: false,
      };

    case ActionTypes.ADD_PODCAST_REQUEST:
      return {
        ...state,
        loadingPodcast: true,
        podcastAddedOrUpdated: false,
      };

    case ActionTypes.ADD_PODCAST_SUCCESS:
      return {
        ...state,
        loadingPodcast: false,
        podcast: payload,
        podcastAddedOrUpdated: true,
      };

    case ActionTypes.ADD_PODCAST_FAILURE:
      return {
        ...state,
        loadingPodcast: false,
        error: payload,
        podcastAddedOrUpdated: false,
      };

    case ActionTypes.REMOVE_PODCAST_REQUEST:
      return {
        ...state,
        loadingPodcasts: true,
        removedPodcast: null,
        podcastDeleted: false,
      };

    case ActionTypes.REMOVE_PODCAST_SUCCESS:
      return {
        ...state,
        loadingPodcasts: false,
        removedPodcast: payload,
        podcastDeleted: true,
      };

    case ActionTypes.REMOVE_PODCAST_FAILURE:
      return {
        ...state,
        loadingPodcasts: false,
        removedPodcast: null,
        error: payload,
        podcastDeleted: false,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        podcasts: null,
        podcast: null,
        removedPodcast: null,
        error: null,
        loadingPodcasts: false,
        podcastAddedOrUpdated: false,
        podcastDeleted: false,
      };

    default:
      return state;
  }
};

export default podcasts;
