export const ActionTypes = {
  /* Premint NFT to IPFS and DB */
  PREMINT_NFTS_REQUEST: 'PREMINT_NFTS_REQUEST',
  PREMINT_NFTS_SUCCESS: 'PREMINT_NFTS_SUCCESS',
  PREMINT_NFTS_FAILURE: 'PREMINT_NFTS_FAILURE',

  /* Edit NFT from DB*/
  EDIT_PREMINT_NFT_REQUEST: 'EDIT_PREMINT_NFT_REQUEST',
  EDIT_PREMINT_NFT_SUCCESS: 'EDIT_PREMINT_NFT_SUCCESS',
  EDIT_PREMINT_NFT_FAILURE: 'EDIT_PREMINT_NFT_FAILURE',

  /* Remove preminted NFT from IPFS and DB*/
  REMOVE_PREMINT_NFTS_REQUEST: 'REMOVE_PREMINT_NFTS_REQUEST',
  REMOVE_PREMINT_NFTS_SUCCESS: 'REMOVE_PREMINT_NFTS_SUCCESS',
  REMOVE_PREMINT_NFTS_FAILURE: 'REMOVE_PREMINT_NFTS_FAILURE',

  /* Get a preminted NFT from DB */
  GET_PREMINTED_NFT_REQUEST: 'GET_PREMINTED_NFT_REQUEST',
  GET_PREMINTED_NFT_SUCCESS: 'GET_PREMINTED_NFT_SUCCESS',
  GET_PREMINTED_NFT_FAILURE: 'GET_PREMINTED_NFT_FAILURE',

  /* Get goruped by bundle id premints NFTs from DB */
  GET_NFTS_REQUEST: 'GET_NFTS_REQUEST',
  GET_NFTS_SUCCESS: 'GET_NFTS_SUCCESS',
  GET_NFTS_FAILURE: 'GET_NFTS_FAILURE',

  /* Get filtered  NFTs */
  GET_FILTERED_NFT: 'GET_FILTERED_NFT',

  /* Mint a NFT */
  MINT_NFT_REQUEST: 'MINT_NFT_REQUEST',
  MINT_NFT_SUCCESS: 'MINT_NFT_SUCCESS',
  MINT_NFT_FAILURE: 'MINT_NFT_FAILURE',

  /* Approve the market contract */
  APPROVE_MARKET_CONTRACT_REQUEST: 'APPROVE_MARKET_CONTRACT_REQUEST',
  APPROVE_MARKET_CONTRACT_SUCCESS: 'APPROVE_MARKET_CONTRACT_SUCCESS',
  APPROVE_MARKET_CONTRACT_FAILURE: 'APPROVE_MARKET_CONTRACT_FAILURE',

  /* List a NFT */
  LIST_NFT_REQUEST: 'LIST_NFT_REQUEST',
  LIST_NFT_SUCCESS: 'LIST_NFT_SUCCESS',
  LIST_NFT_FAILURE: 'LIST_NFT_FAILURE',

  /* Add collection to DB */
  CREATE_COLLECTION_REQUEST: 'CREATE_COLLECTION_REQUEST',
  CREATE_COLLECTION_SUCCESS: 'CREATE_COLLECTION_SUCCESS',
  CREATE_COLLECTION_FAILURE: 'CREATE_COLLECTION_FAILURE',

  /* Import collection to DB */
  IMPORT_COLLECTION_REQUEST: 'IMPORT_COLLECTION_REQUEST',
  IMPORT_COLLECTION_SUCCESS: 'IMPORT_COLLECTION_SUCCESS',
  IMPORT_COLLECTION_FAILURE: 'IMPORT_COLLECTION_FAILURE',

  /* Edit collection to DB */
  EDIT_COLLECTION_REQUEST: 'EDIT_COLLECTION_REQUEST',
  EDIT_COLLECTION_SUCCESS: 'EDIT_COLLECTION_SUCCESS',
  EDIT_COLLECTION_FAILURE: 'EDIT_COLLECTION_FAILURE',

  /* Get a collection from DB */
  GET_COLLECTION_REQUEST: 'GET_COLLECTION_REQUEST',
  GET_COLLECTION_SUCCESS: 'GET_COLLECTION_SUCCESS',
  GET_COLLECTION_FAILURE: 'GET_COLLECTION_FAILURE',

  /* Remove collection from DB*/
  REMOVE_COLLECTION_REQUEST: 'REMOVE_COLLECTION_REQUEST',
  REMOVE_COLLECTION_SUCCESS: 'REMOVE_COLLECTION_SUCCESS',
  REMOVE_COLLECTION_FAILURE: 'REMOVE_COLLECTION_FAILURE',

  /* Get all Collections */
  GET_COLLECTIONS_REQUEST: 'GET_COLLECTIONS_REQUEST',
  GET_COLLECTIONS_SUCCESS: 'GET_COLLECTIONS_SUCCESS',
  GET_COLLECTIONS_FAILURE: 'GET_COLLECTIONS_FAILURE',

  /* Get filter collections */
  GET_FILTERED_COLLECTIONS: 'GET_FILTERED_COLLECTIONS',

  /* Set wallet address */
  SET_WALLET_ADDRESS: 'SET_WALLET_ADDRESS',

  /* Clean store */
  CLEAN_STORE: 'CLEAN_STORE',

  /* Add user */
  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  /* Get all Userss from DB */
  GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',

  /* Get filter users */
  GET_FILTERED_USERS: 'GET_FILTERED_USERS',

  /* Remove user from DB*/
  REMOVE_USER_REQUEST: 'REMOVE_USER_REQUEST',
  REMOVE_USER_SUCCESS: 'REMOVE_USER_SUCCESS',
  REMOVE_USER_FAILURE: 'REMOVE_USER_FAILURE',

  /* Get a user from DB */
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  /* Edit user from DB*/
  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

  /* Add system user */
  ADD_SYSTEM_USER_REQUEST: 'ADD_SYSTEM_USER_REQUEST',
  ADD_SYSTEM_USER_SUCCESS: 'ADD_SYSTEM_USER_SUCCESS',
  ADD_SYSTEM_USER_FAILURE: 'ADD_SYSTEM_USER_FAILURE',

  /* Get all system users from DB */
  GET_ALL_SYSTEM_USERS_REQUEST: 'GET_ALL_SYSTEM_USERS_REQUEST',
  GET_ALL_SYSTEM_USERS_SUCCESS: 'GET_ALL_SYSTEM_USERS_SUCCESS',
  GET_ALL_SYSTEM_USERS_FAILURE: 'GET_ALL_SYSTEM_USERS_FAILURE',

  /* Get filter system users */
  GET_FILTERED_SYSTEM_USERS: 'GET_FILTERED_SYSTEM_USERS',

  /* Remove system user from DB*/
  REMOVE_SYSTEM_USER_REQUEST: 'REMOVE_SYSTEM_USER_REQUEST',
  REMOVE_SYSTEM_USER_SUCCESS: 'REMOVE_SYSTEM_USER_SUCCESS',
  REMOVE_SYSTEM_USER_FAILURE: 'REMOVE_SYSTEM_USER_FAILURE',

  /* Get a system user from DB */
  GET_SYSTEM_USER_REQUEST: 'GET_SYSTEM_USER_REQUEST',
  GET_SYSTEM_USER_SUCCESS: 'GET_SYSTEM_USER_SUCCESS',
  GET_SYSTEM_USER_FAILURE: 'GET_SYSTEM_USER_FAILURE',

  /* Edit system user from DB*/
  EDIT_SYSTEM_USER_REQUEST: 'EDIT_SYSTEM_USER_REQUEST',
  EDIT_SYSTEM_USER_SUCCESS: 'EDIT_SYSTEM_USER_SUCCESS',
  EDIT_SYSTEM_USER_FAILURE: 'EDIT_SYSTEM_USER_FAILURE',

  /* Users metadata*/
  SET_USERS_METADATA: 'SET_USERS_METADATA',

  /* System users metadata*/
  SET_SYSTEM_USERS_METADATA: 'SET_SYSTEM_USERS_METADATA',

  /* Polls */
  GET_POLLS_SUCCESS: 'GET_POLLS_SUCCESS',
  GET_POLLS_FAILURE: 'GET_POLLS_FAILURE',
  GET_POLLS_REQUEST: 'GET_POLLS_REQUEST',
  GET_POLL_SUCCESS: 'GET_POLL_SUCCESS',
  GET_POLL_FAILURE: 'GET_POLL_FAILURE',
  GET_POLL_REQUEST: 'GET_POLL_REQUEST',
  UPDATE_POLL_REQUEST: 'UPDATE_POLL_REQUEST',
  UPDATE_POLL_SUCCESS: 'UPDATE_POLL_SUCCESS',
  UPDATE_POLL_FAILURE: 'UPDATE_POLL_FAILURE',
  ADD_POLL_REQUEST: 'ADD_POLL_REQUEST',
  ADD_POLL_SUCCESS: 'ADD_POLL_SUCCESS',
  ADD_POLL_FAILURE: 'ADD_POLL_FAILURE',
  REMOVE_POLL_REQUEST: 'REMOVE_POLL_REQUEST',
  REMOVE_POLL_SUCCESS: 'REMOVE_POLL_SUCCESS',
  REMOVE_POLL_FAILURE: 'REMOVE_POLL_FAILURE',

  /* Ungroup bundle*/
  UNGROUP_BUNDLE_REQUEST: 'UNGROUP_BUNDLE_REQUEST',
  UNGROUP_BUNDLE_SUCCESS: 'UNGROUP_BUNDLE_SUCCESS',
  UNGROUP_BUNDLE_FAILURE: 'UNGROUP_BUNDLE_FAILURE',

  /* Podcasts */
  GET_PODCASTS_SUCCESS: 'GET_PODCASTS_SUCCESS',
  GET_PODCASTS_FAILURE: 'GET_PODCASTS_FAILURE',
  GET_PODCASTS_REQUEST: 'GET_PODCASTS_REQUEST',
  GET_PODCAST_SUCCESS: 'GET_PODCAST_SUCCESS',
  GET_PODCAST_FAILURE: 'GET_PODCAST_FAILURE',
  GET_PODCAST_REQUEST: 'GET_PODCAST_REQUEST',
  UPDATE_PODCAST_REQUEST: 'UPDATE_PODCAST_REQUEST',
  UPDATE_PODCAST_SUCCESS: 'UPDATE_PODCAST_SUCCESS',
  UPDATE_PODCAST_FAILURE: 'UPDATE_PODCAST_FAILURE',
  ADD_PODCAST_REQUEST: 'ADD_PODCAST_REQUEST',
  ADD_PODCAST_SUCCESS: 'ADD_PODCAST_SUCCESS',
  ADD_PODCAST_FAILURE: 'ADD_PODCAST_FAILURE',
  REMOVE_PODCAST_REQUEST: 'REMOVE_PODCAST_REQUEST',
  REMOVE_PODCAST_SUCCESS: 'REMOVE_PODCAST_SUCCESS',
  REMOVE_PODCAST_FAILURE: 'REMOVE_PODCAST_FAILURE',

  /* Notifications */
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',
  GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',
  ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
  ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',
  REMOVE_NOTIFICATION_REQUEST: 'REMOVE_NOTIFICATION_REQUEST',
  REMOVE_NOTIFICATION_SUCCESS: 'REMOVE_NOTIFICATION_SUCCESS',
  REMOVE_NOTIFICATION_FAILURE: 'REMOVE_NOTIFICATION_FAILURE',
  SEND_NOTIFICATION_REQUEST: 'SEND_NOTIFICATION_REQUEST',
  SEND_NOTIFICATION_SUCCESS: 'SEND_NOTIFICATION_SUCCESS',
  SEND_NOTIFICATION_FAILURE: 'SEND_NOTIFICATION_FAILURE',

  /* Posts and comments */
  GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
  GET_POSTS_FAILURE: 'GET_POSTS_FAILURE',
  GET_POSTS_REQUEST: 'GET_POSTS_REQUEST',
  GET_POST_SUCCESS: 'GET_POST_SUCCESS',
  GET_POST_FAILURE: 'GET_POST_FAILURE',
  GET_POST_REQUEST: 'GET_POST_REQUEST',
  PIN_POST_REQUEST: 'PIN_POST_REQUEST',
  PIN_POST_SUCCESS: 'PIN_POST_SUCCESS',
  PIN_POST_FAILURE: 'PIN_POST_FAILURE',
  REMOVE_POST_REQUEST: 'REMOVE_POST_REQUEST',
  REMOVE_POST_SUCCESS: 'REMOVE_POST_SUCCESS',
  REMOVE_POST_FAILURE: 'REMOVE_POST_FAILURE',
  GET_COMMENT_SUCCESS: 'GET_COMMENT_SUCCESS',
  GET_COMMENT_FAILURE: 'GET_COMMENT_FAILURE',
  GET_COMMENT_REQUEST: 'GET_COMMENT_REQUEST',
  REMOVE_COMMENT_REQUEST: 'REMOVE_COMMENT_REQUEST',
  REMOVE_COMMENT_SUCCESS: 'REMOVE_COMMENT_SUCCESS',
  REMOVE_COMMENT_FAILURE: 'REMOVE_COMMENT_FAILURE',

  /* Add achievement to DB */
  CREATE_ACHIEVEMENT_REQUEST: 'CREATE_ACHIEVEMENT_REQUEST',
  CREATE_ACHIEVEMENT_SUCCESS: 'CREATE_ACHIEVEMENT_SUCCESS',
  CREATE_ACHIEVEMENT_FAILURE: 'CREATE_ACHIEVEMENT_FAILURE',

  /* Edit achievement to DB */
  EDIT_ACHIEVEMENT_REQUEST: 'EDIT_ACHIEVEMENT_REQUEST',
  EDIT_ACHIEVEMENT_SUCCESS: 'EDIT_ACHIEVEMENT_SUCCESS',
  EDIT_ACHIEVEMENT_FAILURE: 'EDIT_ACHIEVEMENT_FAILURE',

  /* Get a achievement from DB */
  GET_ACHIEVEMENT_REQUEST: 'GET_ACHIEVEMENT_REQUEST',
  GET_ACHIEVEMENT_SUCCESS: 'GET_ACHIEVEMENT_SUCCESS',
  GET_ACHIEVEMENT_FAILURE: 'GET_ACHIEVEMENT_FAILURE',

  /* Remove achievement from DB*/
  REMOVE_ACHIEVEMENT_REQUEST: 'REMOVE_ACHIEVEMENT_REQUEST',
  REMOVE_ACHIEVEMENT_SUCCESS: 'REMOVE_ACHIEVEMENT_SUCCESS',
  REMOVE_ACHIEVEMENT_FAILURE: 'REMOVE_ACHIEVEMENT_FAILURE',

  /* Get all achievements */
  GET_ACHIEVEMENTS_REQUEST: 'GET_ACHIEVEMENTS_REQUEST',
  GET_ACHIEVEMENTS_SUCCESS: 'GET_ACHIEVEMENTS_SUCCESS',
  GET_ACHIEVEMENTS_FAILURE: 'GET_ACHIEVEMENTS_FAILURE',

  /* Get filter achievements */
  GET_FILTERED_ACHIEVEMENTS: 'GET_FILTERED_ACHIEVEMENTS',
};
