
import {useSelector} from 'react-redux';
import {Layout, Typography, Spin} from 'antd';
import {CardNFT, FormListing} from './../../index';
import { ListingType } from '../../../utils/status';
import { useNFT } from '../../../context/nft.context';

const {Content} = Layout;

const Listing = () => {
	const {premintedNFT} = useSelector(({nfts}) => nfts);
	const { listingType } = useNFT();
	return (
		<Content
			className='site-layout-background'
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
					margin: '0px 50px 20px 50px',
				}}
			>
				{premintedNFT ? (
					<>
						<CardNFT />
							<FormListing />
					</>
				) : (
					<Spin size='large' style={{display: 'block', margin: 'auto'}} />
				)}
			</div>
			{listingType === ListingType.ENGLISH_AUCTION ? (
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<Typography.Text>Minimum bid:</Typography.Text>
					<Typography.Text disabled>
						The starting bid price will be publicly visible. If you receive a
						bid above this starting value but below your reserve price you can
						accept it at any time.
					</Typography.Text>
					<br />
					<Typography.Text>Reserve price:</Typography.Text>
					<Typography.Text disabled>
						If you don’t receive any bids equal to or greater than your reserve,
						the auction will end without a sale.
					</Typography.Text>
					<br />
				</div>
			) : null}
		</Content>
	);
};

export default Listing;
