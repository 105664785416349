import { ActionTypes } from '../../utils/constants';

const initialState = {
  NFTPreMinted: [],
  loadingPreMint: false,

  editedNFT: [],
  loadingEditNFT: false,

  removedNFT: [],
  loadingRemoveNFT: false,

  premintedNFT: [],
  loadingPremintedNFT: false,

  NFTs: [],
  loading: false,

  filteredNFT: [],

  walletAddress: '',

  loadingApprove: false,
  loadingListNFT: false,

  ungroupedBundle: [],
  loadingUngroupBundle: false,
};

const nfts = (state = initialState, { type, payload }) => {
  switch (type) {
    /* Premint NFT to IPFS and DB */
    case ActionTypes.PREMINT_NFTS_REQUEST:
      return {
        ...state,
        loadingPreMint: true,
      };
    case ActionTypes.PREMINT_NFTS_SUCCESS:
      return {
        ...state,
        loadingPreMint: false,
        NFTPreMinted: payload,
      };
    case ActionTypes.PREMINT_NFTS_FAILURE:
      return {
        ...state,
        loadingPreMint: false,
        NFTPreMinted: payload,
      };

    /* Edit NFT from DB*/
    case ActionTypes.EDIT_PREMINT_NFT_REQUEST:
      return {
        ...state,
        loadingEditNFT: true,
      };
    case ActionTypes.EDIT_PREMINT_NFT_SUCCESS:
      return {
        ...state,
        loadingEditNFT: false,
        editedNFT: payload,
      };
    case ActionTypes.EDIT_PREMINT_NFT_FAILURE:
      return {
        ...state,
        loadingEditNFT: false,
        editedNFT: payload,
      };

    /* Remove preminted NFT from IPFS and DB*/
    case ActionTypes.REMOVE_PREMINT_NFTS_REQUEST:
      return {
        ...state,
        loadingRemoveNFT: true,
      };
    case ActionTypes.REMOVE_PREMINT_NFTS_SUCCESS:
      return {
        ...state,
        loadingRemoveNFT: false,
        removedNFT: payload,
      };
    case ActionTypes.REMOVE_PREMINT_NFTS_FAILURE:
      return {
        ...state,
        loadingRemoveNFT: false,
        removedNFT: payload,
      };

    /* Get a preminted NFT from DB */
    case ActionTypes.GET_PREMINTED_NFT_REQUEST:
      return {
        ...state,
        loadingPremintedNFT: true,
      };
    case ActionTypes.GET_PREMINTED_NFT_SUCCESS:
      return {
        ...state,
        loadingPremintedNFT: false,
        premintedNFT: payload,
      };
    case ActionTypes.GET_PREMINTED_NFT_FAILURE:
      return {
        ...state,
        loadingPremintedNFT: false,
      };

    /* Get all grouped by bundle id premints NFTs from DB */
    case ActionTypes.GET_NFTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        NFTs: payload,
      };
    case ActionTypes.GET_NFTS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    /* Get filtered  NFTs */
    case ActionTypes.GET_FILTERED_NFT:
      return {
        ...state,
        filteredNFT: payload,
      };

    case ActionTypes.APPROVE_MARKET_CONTRACT_REQUEST:
      return {
        ...state,
        loadingApprove: true,
      };
    case ActionTypes.APPROVE_MARKET_CONTRACT_SUCCESS:
      return {
        ...state,
        loadingApprove: false,
      };
    case ActionTypes.APPROVE_MARKET_CONTRACT_FAILURE:
      return {
        ...state,
        loadingApprove: false,
      };

    case ActionTypes.LIST_NFT_REQUEST:
      return {
        ...state,
        loadingListNFT: true,
      };
    case ActionTypes.LIST_NFT_SUCCESS:
      return {
        ...state,
        loadingListNFT: false,
      };
    case ActionTypes.LIST_NFT_FAILURE:
      return {
        ...state,
        loadingListNFT: false,
      };

    case ActionTypes.SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: payload,
      };

    /* Ungroup bundle */
    case ActionTypes.UNGROUP_BUNDLE_REQUEST:
      return {
        ...state,
        loadingUngroupBundle: true,
      };
    case ActionTypes.UNGROUP_BUNDLE_SUCCESS:
      return {
        ...state,
        loadingUngroupBundle: false,
        ungroupedBundle: payload,
      };
    case ActionTypes.UNGROUP_BUNDLE_FAILURE:
      return {
        ...state,
        loadingUngroupBundle: false,
        ungroupedBundle: payload,
      };

    case ActionTypes.CLEAN_STORE:
      return {
        ...state,
        /* NFTs: null, */
        NFTPreMinted: null,
        premintedNFT: null,
        filteredNFT: null,
        removedNFT: null,
        editedNFT: null,
        ungroupedBundle: null,
      };

    default:
      return state;
  }
};

export default nfts;
