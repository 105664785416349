import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getFilteredNFT, cleanStore} from '../../../redux/actions/nfts';
import {Form, Input, Button} from 'antd';

const Searchbar = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const {NFTs} = useSelector(({nfts}) => nfts);

	const onFinish = (values) => {
		dispatch(getFilteredNFT(values, NFTs));
	};

	const onReset = () => {
		form.resetFields();
		dispatch(getFilteredNFT({name: '', IpfsHash: ''}, NFTs));
		cleanStore();
	};

	return (
		<>
			<Form
				form={form}
				name='control-hooks'
				onFinish={onFinish}
				style={{
					display: 'flex',
					backgroundColor: '#fff',
					padding: 10,
					margin: '15px 15px 0 15px',
				}}
			>
				<Form.Item name='name' label='NFT name:' style={{margin: '0'}}>
					<Input placeholder='Place enter' />
				</Form.Item>

				<Form.Item style={{margin: '0 20px 0 auto'}}>
					<Button htmlType='button' onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
				<Form.Item style={{margin: '0'}}>
					<Button type='primary' htmlType='submit'>
						Search
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default Searchbar;
